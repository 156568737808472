// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import Customform from '../../blocks/customform/src/Customform';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPasswordWeb';
import AdminForgotPasswordWeb from '../../blocks/forgot-password/src/AdminForgotPasswordWeb';
import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPasswordWeb';
import AdminResetPasswordWeb from '../../blocks/forgot-password/src/AdminResetPasswordWeb';
import VerifyEmailWeb from '../../blocks/forgot-password/src/VerifyEmailWeb';

import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3';
import Analytics3Web from '../../blocks/Analytics3/src/Analytics3Web';

import MsaccessIntegration2 from '../../blocks/MsaccessIntegration2/src/MsaccessIntegration2';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Settings2 from '../../blocks/Settings2/src/Settings2';

import UserSettingsWeb from '../../blocks/Settings2/src/UserSettingsWeb';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import TwofactorAuthentication from '../../blocks/TwofactorAuthentication/src/TwofactorAuthentication';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';

import UsersListWeb from '../../blocks/RolesPermissions2/src/UsersListWeb';

import PermissionWeb from '../../blocks/RolesPermissions2/src/PermissionWeb';

import ContentManagementWeb from '../../blocks/RolesPermissions2/src/ContentManagementWeb';

import EditingEarningWeb from '../../blocks/RolesPermissions2/src/EditingEarningWeb';

import EditingDisclaimerWeb from '../../blocks/RolesPermissions2/src/EditingDisclaimerWeb';
import EditingContactSupportWeb from "../../blocks/RolesPermissions2/src/EditingContactSupportweb"
import EditingPrivacyPolicyWeb from '../../blocks/RolesPermissions2/src/EditingPrivacyPolicyWeb';
import Captcha from '../../blocks/Captcha/src/Captcha';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import EmailAccountLoginWeb from '../../blocks/email-account-login/src/EmailAccountLoginWeb';
import EmailAccountAdminLoginWeb from '../../blocks/email-account-login/src/EmailAccountAdminLoginWeb';

import DisclaimerWeb from '../../blocks/email-account-login/src/DisclaimerWeb';
import EarningDetailsWeb from '../../blocks/email-account-login/src/EarningDetailsWeb';

import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';

import EmailAccountSignUpWeb from '../../blocks/email-account-registration/src/EmailAccountSignUpWeb';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import DropDownFeature2 from '../../blocks/DropDownFeature2/src/DropDownFeature2';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';



import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';

import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import RequestListWeb from '../../blocks/AdminConsole3/src/RequestListWeb';
import AdminDetailWeb from '../../blocks/AdminConsole3/src/AdminDetailWeb';
import AdminSettingsWeb from '../../blocks/AdminConsole3/src/AdminSettingWeb';
import AdminUpdateSettingWeb from '../../blocks/AdminConsole3/src/AdminUpdateSettingWeb';

import AdminUpdateProfileWeb from '../../blocks/AdminConsole3/src/AdminUpdateProfileWeb';

import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import 'antd/dist/antd.css';

import ContentReportsWeb from '../../blocks/user-profile-basic/src/ContentReportsWeb';
import AccountWeb from '../../blocks/user-profile-basic/src/AccountWeb';
import MyDocsWeb from '../../blocks/user-profile-basic/src/MyDocsWeb';
const routeMap = {
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  // core: {
  //   component: core,
  //   path: '/core'
  // },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPasswordWeb'
  },
  AdminForgotPasswordWeb: {
    component: AdminForgotPasswordWeb,
    path: '/admin/ForgotPasswordWeb'
  },
  Analytics3Web: {
    component: Analytics3Web,
    path: '/admin/Analytics3Web'
  },
  AdminDetailWeb: {
    component: AdminDetailWeb,
    path: '/admin/AdminDetailWeb'
  },
  AdminSettingWeb: {
    component: AdminSettingsWeb,
    path: '/admin/AdminSettingWeb'
  },
  AdminUpdateProfileWeb: {
    component: AdminUpdateProfileWeb,
    path: '/admin/AdminUpdateProfileWeb'
  },
  AdminUpdateSettingWeb: {
    component: AdminUpdateSettingWeb,
    path: '/admin/AdminUpdateSettingWeb'
  },
  ResetPasswordWeb: {
    component: ResetPasswordWeb,
    path: '/reset-password/:slug'
  },
  AdminResetPasswordWeb: {
    component: AdminResetPasswordWeb,
    path: '/admin/reset-password/:slug'
  },
  DisclaimerWeb: {
    component: DisclaimerWeb,
    path: '/DisclaimerWeb'
  },
  EarningDetailsWeb: {
    component: EarningDetailsWeb,
    path: '/EarningDetailsWeb'
  },

  VerifyEmailWeb: {
    component: VerifyEmailWeb,
    path: '/verify-email/:slug'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Analytics3: {
    component: Analytics3,
    path: '/Analytics3'
  },
  MsaccessIntegration2: {
    component: MsaccessIntegration2,
    path: '/MsaccessIntegration2'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },

  UserSettingsWeb: {
    component: UserSettingsWeb,
    path: '/UserSettingsWeb'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  TwofactorAuthentication: {
    component: TwofactorAuthentication,
    path: '/TwofactorAuthentication'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  UsersListWeb: {
    component: UsersListWeb,
    path: '/admin/UsersListWeb'
  },

  RequestListWeb: {
    component: RequestListWeb,
    path: '/admin/RequestListWeb'
  },

  PermissionWeb: {
    component: PermissionWeb,
    path: '/admin/PermissionWeb'
  },

  ContentManagementWeb: {
    component: ContentManagementWeb,
    path: '/admin/ContentManagementWeb'
  },

  EditingDisclaimerWeb: {
    component: EditingDisclaimerWeb,
    path: '/admin/EditingDisclaimerWeb'
  },

  EditingEarningWeb: {
    component: EditingEarningWeb,
    path: '/admin/EditingEarningWeb'
  },

  EditingPrivacyPolicyWeb: {
    component: EditingPrivacyPolicyWeb,
    path: '/admin/EditingPrivacyPolicyWeb'
  },
  EditingContactSupportWeb: {
    component: EditingContactSupportWeb,
    path: '/admin/EditingContactSupportWeb'
  },

  Captcha: {
    component: Captcha,
    path: '/Captcha'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  DropDownFeature2: {
    component: DropDownFeature2,
    path: '/DropDownFeature2'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },

  Home: {
    component: EmailAccountLoginWeb,
    path: '/',
    exact: true
  },

  EmailAccountAdminLoginWeb: {
    component: EmailAccountAdminLoginWeb,
    path: '/admin/login'
  },
  EmailAccountSignUpWeb: {
    component: EmailAccountSignUpWeb,
    path: '/EmailAccountSignUpWeb'
  },
  ContentReportsWeb: {
    component: ContentReportsWeb,
    path: '/ContentReportsWeb'
  },
  AccountWeb: {
    component: AccountWeb,
    path: '/AccountWeb'
  },
  MyDocsWeb: {
    component: MyDocsWeb,
    path: '/MyDocsWeb'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
  // AdminDetail: {
  //   component: AdminDetailWeb,
  //   path: '/AdminDetailWeb'
  // },
  // AdminUpdate: {
  //   component: AdminUpdateProfileWeb,
  //   path: '/AdminUpdateProfile'
  // },
  // AdminSetting: {
  //   component: AdminSettingWeb,
  //   path: '/AdminSetting'
  // }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
