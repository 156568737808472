import { Row, Col, Select, Avatar, Spin, Modal, Form , DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import { UserOutlined, ArrowUpOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import { openNotification } from "../../../components/src/Notification.web";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
import axios from "axios";
import moment , {Moment} from "moment";
import { EventValue } from 'rc-picker/lib/interface';
import { UnAuthorized } from "../../../components/src/helper";
const { Option } = Select;
const { baseUrl } = urlRepacthaConfig();
import "../assets/analyticsweb.css"
const Analytics3Web = (props: any) => {


  const [graphData, setGraphData] = useState<any>([])
  const [selectedInterval, setSelectedInterval] = useState('yearly')

  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')

  const handleStartDateChange = (date :any) => {
    setStartDate(date);
    if (date && endDate) {
      getQueries(date, endDate);
    }
  };

  const handleEndDateChange = (date :any) => {
    setEndDate(date);
    if (date && startDate)  {
      // Make API call using startDate and endDate
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(date).format('YYYY-MM-DD');
      getQueries(formattedStartDate , formattedEndDate)
    }
  };
  const getDefaultDates = (): [EventValue<Moment>, EventValue<Moment>] => {
    const endDate = moment(); // Current date
    const startDate = moment().subtract(1, 'month'); // Set the default end date
    return [startDate, endDate];
  };  
  const [selectedDates, setSelectedDates] = useState<[EventValue<Moment>, EventValue<Moment>]>(getDefaultDates());
  getDefaultDates()
  console.log(selectedDates)
  console.log(setSelectedDates)
  function handleChange(value: any) {
    setSelectedInterval(value)
    getListenersOverview(value)

  }
  function render_element(){
    if(loadingQueriesData){
      return (
        <Row
          align="middle"
          justify="center"
          style={{ height: "300px", width: "100%" }}
        >
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      )
    }else{
      return (
        <>
          {!queriesData && (
            <Row
              justify="center"
              align="middle"
              className="analytic-queries-data "
              style={{ height: "300px", fontSize: "20px" }}
            >
              No Data Found
            </Row>
          )}
          {queriesData &&
            queriesData.map((ind: any) => (
              <Row
                justify="space-between"
                className="mb-1"
                style={{ height: "30px", padding: "10px" }}
              >
                <Col span={19}>
                  {" "}
                  {ind.customer.first_name}{" "}
                  {ind.customer.last_name}
                </Col>
                <Col span={5}>{ind.customer.queries}</Col>
              </Row>
            ))}
        </>
      )
    }
  }
  const renderLegend = (props: any) => {
    const { payload } = props;
    // console.log(payload);
    return (
      <ul className="analytics-home-bar-chart-legend">
        {// style={{color: entry.color}}
          payload.map((entry: any, index: any) => (
            <li key={`item-${index}`}>
              {entry.value ? "New Licensors" : entry.value}
            </li>
          ))}
      </ul>
    );
  };
  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingQueriesData, setLoadingQueriesData] = useState(true)

  const currentYear = new Date().getFullYear();
  const [overviewData, setOverviewData] = useState<any>('')
  const [queriesData, setQueriesData] = useState<any>('')
  const [recentListeners, setRecentListeners] = useState<any>('')
  const [openQuerieModal, setOpenQuerieModal] = useState<any>(false);
  const [openCustomerMoadl, setOpenCustomerMoadl] = useState<any>(false);
  const [selectedYear, setSelectedYear] = useState<any>(currentYear);




  const token: any = localStorage.getItem("access_token");
  const getListenersOverview = async (type: any) => {
    setLoading(true)
    await axios
      .get(`${baseUrl}admin/users`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        }, params: {
          "keyword": type
        }
      })
      .then((res: any) => {
        setOverviewData(res.data);
      })
      .catch((err: any) => {
        // err.response.status == 404||401 ? UnAuthorized(props) : ""
        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);
      })
  };
  const getGraphData = async (type: any) => {
    setLoadingData(true)
    await axios
      .get(`${baseUrl}admin/monthly/data`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        }, params: {
          "year": type
        }
      })
      .then((res: any) => {
        const data = res.data
        const result = Object.keys(data)
          .map(key => ({ month: key.substring(0, 3), customer: data[key] }));
        setGraphData(result);
      })
      .catch((err: any) => {
        // err.response.status == 404||401 ? UnAuthorized(props) : ""

        if (err.response.status === 401 || 404) {
          UnAuthorized('session expired')
        }

        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoadingData(false);
      })
  };
  const getQueries = async (first_date :any , second_date :any) => {
    await axios
      .get(`${baseUrl}/admin/liecensor/queries`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        }, 
        params: {
              "start_date": first_date,
             "end_date":  second_date
        }
      })
      .then((res: any) => {
        console.log("res",res)
        const data = res.data.queries
        const result = Object.keys(data)
          .map(key => ({ month: key.substring(0, 3), customer: data[key] }));
        setQueriesData(result);
      })
      .catch((err: any) => {
        if (err.response.status === 401 || 404) {
        setQueriesData("");
          
        }
        openNotification(err?.response?.data?.full_message, "error")
        setQueriesData("");
      }).finally(() => {
        setLoadingQueriesData(false);

      })
  };

  const getRecentListeners = async () => {
    await axios
      .get(`${baseUrl}/admin/recent/user`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        setRecentListeners(res.data.recent_user);
      })
      .catch((err: any) => {
        // err.response.status == 404||401 ? UnAuthorized(props) : ""
        if (err.response.status === 401 || 404) {
          UnAuthorized('session expired')
        }
        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);

      })
  };
  useEffect(() => {
    getListenersOverview(currentYear);
    getGraphData(currentYear);
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD');
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    getQueries(defaultStartDate, defaultEndDate);
    getRecentListeners();
  }, [])
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };
  const handleSelectedYear = (date: any, dateString: any) => {
    setSelectedYear(dateString);
    getGraphData(dateString);
  }
  const disabledYear = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf('year');
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      <Row className="mt-5">
        <Col lg={5} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} md={17} sm={16} xs={14}>
          <p className="main-head-txt-admin-home">Analytics</p>
          {loading ? <Row align="middle" justify="center" data-testid="spinner"><Col>
            <Spin size="large" />
          </Col></Row> : <>



            <Col className="admin-analytics-home-overview" data-testid="data">
              <Row justify="space-between" className="mb-1">
                <p className="main2-head-txt-admin-home "> Overview</p>
                <Row className="select_title" justify="center">Show:
                  <Select
                    className="admin-home-analytics-select"
                    // placeholder="Show:"
                    data-testid="yearDropdown"

                    defaultValue="yearly"
                    value={selectedInterval}
                    style={{ width: 150 }}
                    onChange={handleChange}
                  >
                    <Option value="yearly"> In This Year</Option>
                    <Option value="monthly"> In This Month</Option>
                    <Option value="weekly"> In This Week</Option>
                    {/* <Option value="disabled" disabled>
                    Disabled
                  </Option> */}
                  </Select></Row>
              </Row>

              <Row gutter={[16, 16]} justify="space-around">
                <Col md={3} xs={2} sm={2} lg={0} />
                <Col
                  lg={5}
                  md={8}
                  sm={9}
                  xs={20}
                  className="admin-analytics-home-overview-sec-cards"
                >
                  <span className="overview-sec-up-arrow">
                    <ArrowUpOutlined translate className="overview-sec-arrow" />
                  </span>

                  <p className="overview-sec-arrow-txt1">{overviewData.all_liecnsor}</p>
                  <p>Licensors on Platform</p>
                </Col>
                <Col md={2} xs={2} sm={2} lg={0} />
                <Col xs={2} sm={0} />
                <Col
                  lg={5}
                  md={8}
                  sm={9}
                  xs={20}
                  className="admin-analytics-home-overview-sec-cards"
                >
                  <span className="overview-sec-up-arrow">
                    <ArrowUpOutlined translate className="overview-sec-arrow" />
                  </span>

                  <p className="overview-sec-arrow-txt1">{overviewData.weekly_liecensors}</p>
                  <p>Number of Users Joined this {selectedInterval.substring(0, selectedInterval.length - 2)}</p>
                </Col>
                <Col md={3} xs={2} sm={2} lg={0} />
                <Col md={3} xs={2} sm={2} lg={0} />
                <Col
                  lg={5}
                  md={8}
                  sm={9}
                  xs={20}
                  className="admin-analytics-home-overview-sec-cards"
                >
                  <span className="overview-sec-up-arrow">
                    <ArrowUpOutlined translate className="overview-sec-arrow" />
                  </span>

                  <p className="overview-sec-arrow-txt1">{overviewData.sent_invites}</p>
                  <p>Invites Sent</p>
                </Col><Col xs={2} sm={0} />
                <Col md={2} xs={2} sm={2} lg={0} />
                <Col
                  lg={5}
                  md={8}
                  sm={9}
                  xs={20}
                  className="admin-analytics-home-overview-sec-cards"
                >
                  <span className="overview-sec-up-arrow">
                    <ArrowUpOutlined translate className="overview-sec-arrow" />
                  </span>

                  <p className="overview-sec-arrow-txt1">{overviewData.accepted_invites}</p>
                  <p>Invites Accepted</p>
                </Col>
                <Col md={3} xs={2} sm={2} lg={0} />
              </Row>
            </Col>

            <Col className="admin-analytics-home-licens-platfrm">
              <Row justify="space-between" className="mb-1">
                <Col className="main2-head-txt-admin-home">
                  Licensors on Platform
                </Col>
                <Col className="dashbord_select">
                  <Row className="select_title_yearPicker">Show:
                    {/* @ts-ignore */}
                    <DatePicker disabledDate={disabledYear}
                      placeholder={selectedYear}
                      onChange={handleSelectedYear}
                      style={{ color: "black" }}
                      picker="year" />
                  </Row>

                </Col>
              </Row>

              <Row
                gutter={[16, 16]}
              // className="admin-analytics-home-licens-platfrm-sec"
              >
                <Col
                  span={24}
                // className="admin-analytics-home-licens-platfrm-sec-graph"
                >

                  <ResponsiveContainer width="100%" height="100%" className='scroll_overflow'>
                    <>
                      {loadingData ? <Row align="middle" justify="center" style={{ height: "300px" }}><Col>
                        <Spin size="large" />
                      </Col></Row> : (<>


                        <BarChart
                          width={1000}
                          height={300}
                          data={graphData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          barSize={8}
                        >
                          <XAxis
                            dataKey="month"
                            scale="point"
                            padding={{ left: 10, right: 10 }}
                          />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend
                            align="left"
                            iconType="circle"
                            iconSize={16}
                            content={renderLegend}
                          />
                          {/* <CartesianGrid strokeDasharray="3 3" /> */}
                          <Bar
                            dataKey="customer"
                            fill="#FAC13C"
                            background={{ fill: "#5E5F62" }}
                          />
                        </BarChart> </>)}</>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Col>

            <Col className="admin-analytics-home-licens-platfrm">
              <Row gutter={32} >
                <Col lg={24} xl={15} md={24} sm={24} xs={24}>
                  <Row justify="space-between" className="mb-1" data-testid="rangepicker" >
                    <p className="main2-head-txt-admin-home" data-testid="rangepicker" >Queries</p>
                    <Row style={{width: "60%" , justifyContent: "space-between" }}>
                            <Form className="form_query">
                                      <Form.Item label="Start date" name="startDate" className="custom_form" >
                                        <DatePicker value={startDate} disabledDate={disabledDate} defaultValue={moment(startDate)} onChange={handleStartDateChange}  format="MM/DD/YYYY" />
                                       
                                      </Form.Item>
                                    
                                      <Form.Item label=" End date" name="endDate"className="custom_form" >
                                          <DatePicker value={endDate} disabledDate={disabledDate} defaultValue={moment(endDate)}  onChange={handleEndDateChange}  format="MM/DD/YYYY"/>
                                      </Form.Item>
                        </Form>
                    </Row>
                  </Row>
                  <Col className=" admin-analytics-home-queries-sec mb-2">
                    <Row
                      className="highlight-head"
                      justify="space-between"
                      align="middle"
                    >

                      <Col span={16} className="font_600">USERNAME</Col> <Col span={8} className="font_600">QUERIES SUBMITTED</Col>
                    </Row>
                    <Row >
                      <Col span={24} style={{ height: "255px", minHeight: "255px", maxHeight: "235px", width: "100%", overflow: "hidden" }}>
                        {render_element()}
                      </Col>

                    </Row>


                    <Row style={{ height: "20px", minHeight: "20px", margin:"2rem" }} > <button className="admin-btn-green cursor_pointer" onClick={() => setOpenQuerieModal(true)} data-testid="viewAll">View All Queries</button></Row>
                  </Col>
                </Col>
                {
                  openQuerieModal &&
                  <Modal
                    className='admin-settings-pwd-update-modal'
                    data-testid="queries"
                    // title=" Submitted Queries" s
                    footer={false}
                    visible={openQuerieModal}
                  //  onOk={handleOk} onCancel={handleCancel}
                  >
                    <Row
                      className="font_size26 font_500 mb-1"
                      align="middle"
                      justify="center"
                    >
                      <Col span={22} style={{ textAlignLast: "center" }}>
                        {" "}
                        Queries
                      </Col>{" "}
                      <Col span={2}>
                        <CloseCircleOutlined
                          onClick={() => setOpenQuerieModal(false)}
                          // translate={undefined}
                          translate="true"
                        />
                      </Col>
                    </Row>
                    <Row
                      className="highlight-head"
                      justify="space-between"
                      align="middle"
                    >

                    <Col span={16}>USERNAME</Col> <Col span={8}>QUERIES SUBMITTED</Col>
                    </Row>
                    <Row
                      style={{ height: "270px", minHeight: "270px", overflowY: "scroll" }}>
                      <Col span={24} style={{ height: "270px", minHeight: "270px" }}>
                        {render_element()}
                      </Col>
                    </Row>
                  </Modal>
                }

                <Col lg={24} xl={9} md={24} sm={24} xs={24}>
                  <Row>
                    <Row className="mb-1" data-testid="user-list">
                      <p className="main2-head-txt-admin-home">
                        Most Recent Licensors on Platform
                      </p>
                    </Row>

                    <Col className="admin-analytics-home-licensors-plat-sec">

                      <Row style={{ height: "280px", minHeight: "280px", overflow: "hidden" }}>


                        {
                          recentListeners && recentListeners.map((ind: any) => (
                            // let str="Hello Folks!"
                            // let ind[0] = str.charAt(0);
                            <>
                              <Row className="analtytic-licensors-recent-row" data-testid="user-list">
                                <Avatar size={34} alt="Name"
                                  style={{ color: 'Black', backgroundColor: '#fff', fontSize: '15px', fontWeight: 600 }}
                                  className="analtytic-licensors-recent-row-img "
                                >
                                  {ind[0].charAt(0).toUpperCase()}
                                  {ind[1].charAt(0).toUpperCase()}
                                </Avatar>

                                <Col className="analtytic-licensors-recent-col" data-testid="user-list">
                                  <Row>{ind[0]}{" "} {ind[1]}</Row>
                                  <Row>{ind[2]}</Row></Col></Row>

                            </>
                          ))
                        }
                      </Row>
                      <Row style={{margin:"2rem"}}>
                        <button className="admin-btn-green mt-1 cursor_pointer" data-testid="moreCustomerBtn" onClick={() => setOpenCustomerMoadl(true)}>
                          View more Customers
                        </button></Row>
                      {
                        openCustomerMoadl &&
                        <Modal
                          className='admin-settings-pwd-update-modal'

                          // title=" Submitted Queries" s
                          footer={false}
                          visible={openCustomerMoadl}
                        //  onOk={handleOk} onCancel={handleCancel}
                        >
                          <Row
                            className="font_size26 font_500 mb-1"
                            align="middle"
                            justify="center"
                          >
                            <Col span={22}
                            // style={{ textAlignLast: "center" }}
                            >
                              {" "}
                              Most Recent Licensors on Platform
                            </Col>{" "}
                            <Col span={2}>
                              <Row justify="end"> <CloseCircleOutlined
                                onClick={() => setOpenCustomerMoadl(false)}
                                // translate={undefined}
                                translate="true"
                              /></Row>
                            </Col>
                          </Row>


                          <Row style={{ height: "270px", minHeight: "270px", overflowY: "scroll" }} data-testid="user-list">



                            {
                              recentListeners && recentListeners.map((ind: any) => (
                                <>
                                  <Row className="analtytic-licensors-recent-row"  >


                                    <Avatar size={34} alt="Name"
                                      style={{ color: 'Black', backgroundColor: '#fff', fontSize: '15px', fontWeight: 600 }}
                                      className="analtytic-licensors-recent-row-img " data-testid="user-list"
                                    >
                                      {ind[0].charAt(0).toUpperCase()}
                                      {ind[1].charAt(0).toUpperCase()}
                                    </Avatar>

                                    <Col className="analtytic-licensors-recent-col" data-testid="user-list">
                                      <Row >{ind[0]}{" "} {ind[1]}</Row>
                                      <Row>{ind[2]}</Row></Col></Row>

                                </>
                              ))
                            }
                          </Row>
                        </Modal>

                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col></>}
        </Col>
        <Col lg={1} md={1} />
      </Row>
    </>
  );
};

export default Analytics3Web;
