//@ts-nocheck
import {
  SettingFilled,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Col, Row, Avatar, Menu ,  Dropdown ,Tabs } from "antd";
import type { MenuProps } from 'antd';
import { NavLink } from "react-router-dom";
import { UnAuthorized } from "../../components/src/helper";
import React, { useState } from "react";
const logo = require("./image_Greenapple.png");
import "../../blocks/user-profile-basic/src/ContentReportsWeb";
import { cursorTo } from "readline";
import MyDocsWeb from "../../blocks/user-profile-basic/src/MyDocsWeb";

const { TabPane } = Tabs;

const HeaderWeb = (props: any) => {
  const [showMenu, setShowMenu] = useState(false);
  // const [tabKey , settabKey] = useState("")
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <NavLink to="/AccountWeb">
          Account
      </NavLink>
      ),
      icon: <UserOutlined/>
    },
    {
      key: '2',
      label: (
        <NavLink to="/MyDocsWeb">
            My Docs
        </NavLink>
      ),
      icon: <UserOutlined/>
    },
    {
      key: '3',
      label: (
        <NavLink
        exact={true}
        to="/UserSettingsWeb"
        activeClassName="active"
        // className="pl-4 text_green font_size16 inactive"
      >
        Settings
      </NavLink>
      ),
      icon: <SettingOutlined/>
    },
    {
      key: '4',
      label: (
        <NavLink
        exact={true}
        to="/"
        onClick={()=>{handleSignOut()}}  
        activeClassName="active"
      >
        Sign Out
      </NavLink>
      ),
      icon: <LogoutOutlined/>
    }    
    ]
  const menu = (
    <Menu className="">
      <Menu.Item key="0">
        <NavLink to="/ContentReportsWeb">
          {" "}
          <Col
            className="text_white"

            // onClick={() =>
            //   props.navigation.history.push("/ContentReportsWeb")
            // }
          >
            Earnings Reports
          </Col>
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="1"> */}
        {/* <Col
          className="pr-1 cursor_p header"
          onClick={() =>
            window.location.replace("https://www.fluentaac.com/learn-aac")
          }
        >
          AAC Lessons
        </Col> */}
        {/* <NavLink to="/AccountWeb">
          {" "}
          <Col className="text_white">Account</Col>
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="2">
        {/* <Col
          className="pr-1 cursor_p header"
          onClick={() =>
            window.location.replace("https://www.fluentaac.com/learn-aac")
          }
        >
          AAC Lessons
        </Col> */}
        <NavLink to="/UserSettingsWeb">
          <Col className="text_green">
            <SettingOutlined translate="true" />
          </Col>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <Col className="text_white">
          {" "}
          <Avatar size="small" icon={<UserOutlined translate="true" />} />
        </Col>
      </Menu.Item>
    </Menu>
  );
  const handleManu = (e: any) => {
    e.preventDefault();
    setShowMenu(true);
  };
  const handleDocs = (props: any) => {
    <MyDocsWeb  tabKey = {'3'} props={props}/>
  }
  const handleSignOut = () => {
    localStorage.removeItem("user_token");
    window.location.replace("/");
    console.log("signout called")
    // localStorage.removeItem("user_token");
    // props.navigation.history.push("/");;;
  }
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 570;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <Row
      justify="center"
      align="middle"
      className="p-1"
      // style={{ height: "100px" }}
    >
      <Col
        //  lg={18} md={21} sm={23} xs={23}
        span={23}
      >
        <Row align="middle" className="header_border ">
          <Col span={4}>
            {/* <NavLink to="/AccountWeb" exact={true}> */}
              <img
                src={logo}
                alt="logo"
                height="50px"
                width="230px"
              />
            {/* </NavLink> */}
          </Col>
          <Col span={20}>
            <Row justify="end">
              {width < breakpoint ? (
                <>
                  <Dropdown className="" overlay={menu} >
                    <Row className="">
                      <MenuOutlined translate="true" style={{ fontSize: "18px" }} />
                    </Row>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Row justify="end" style={{width: "15%" ,justifyContent: "space-between" , marginRight: "10px"}}>
                    <Col>
                      <NavLink
                        to="/ContentReportsWeb"
                        exact={true}
                        activeClassName="active"
                        className="text_white inactive"
                      >
                        {" "}
                        Earnings Reports
                      </NavLink>
                    </Col>
                    {" "}
                    <Col span={8} >
                        <Dropdown
                          overlayStyle={{
                            border: '1px solid black',
                            width: "7%",
                            height: "5%",
                          }}
                          overlay={
                            <Menu style={{justifyContent: "space-between"}}>
                              {items.map((item) => (
                                <Menu.Item key={item.key} style={{alignContent: "center" , fontSize: "14px" , color: "white" }}>
                                  <span style={{ marginRight: '10px'}}>{item.icon}</span>
                                  {item.label}
                                </Menu.Item>
                              ))}
                            </Menu>
                          }
                          placement="bottomRight"
                          trigger={['click']}

                        >
                          <Avatar size="small" style={{cursor: "pointer"}} icon={<UserOutlined />} />
                        </Dropdown>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderWeb;
