import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import { openNotification } from "../../../components/src/Notification.web";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Country, State, City } from "country-state-city";
import form from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import _ from "lodash";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import { AdminUnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
import "../assets/userListweb.css"
// Delete@3x
const deleteIcon = require("./Delete@3x.png");
const { baseUrl } = urlRepacthaConfig();

const EditIcon = require("./Edit User@3x.png");

const UsersListWeb = (props: any) => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { Option } = Select;
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditmodal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addLicensorModal , setAddLicensorModal] = useState(false)
  const [saveModal, setSaveModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [multipleDelete, setMultipleDelete] = useState<any>([]);
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false)
  const [editedLicensorDetails, seteditedLicensorDetails] = useState({});
  const handleOk4 = () => {
    setChangeRoleModal(false);
    setBtnLoading(true);
  };

  const handleCancel2 = () => {
    setAddNewUserModal(false);
  };

  const [data, setData] = useState();
  const token: any = localStorage.getItem("access_token");
  const getUserList = async () => {
    await axios
      .get(`${baseUrl}admin/all/user`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        setData(res.data.requested_user);
      })
      .catch((error: any) => {
        if (error.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return error.response;
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const [adminRole, setAdminRole] = useState([]);
  const getAllRole = async () => {
    await axios
      .get(`${baseUrl}admin/all/role`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        setAdminRole(res.data.role);
      })
      .catch((error: any) => {
        if (error.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return error.response;
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  useEffect(() => {
    getUserList();
    getAllRole();
  }, []);

  const [userDetails, setUserDetails] = useState("");
  const handleNewUser = (values: any) => {
    setUserDetails(values);
    setAddLicensorModal(true)
  };
  const createNewUser = async (values: any) => {
    setBtnLoading(true);
    console.log("userDetails===>",values);
    await axios
      .post(`${baseUrl}admin/user/create`, userDetails, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res) => {
        setAddLicensorModal(false)
        setAddNewUserModal(false)
        getUserList();
        setSaveModal(false);
        form.resetFields();
        openNotification(res.data.success[0].account, "success");
      })
      .catch((err) => {
        openNotification(err?.response?.data?.full_message, "error");
      })
      .finally(() => {
        setBtnLoading(false);
        form.resetFields();
        setAddLicensorModal(false)
        setAddNewUserModal(false)
        setSaveModal(false);
      });
  };
  const [editUser, setEditUser] = useState<any>("");
  const getUserDetails = async (id: any) => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/admin/user/${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res) => {
        form1.setFieldsValue({
          address: res.data.user_detail.address,
          city: res.data.user_detail.city,
          country: res.data.user_detail.country,
          email: res.data.user_detail.email,
          first_name: res.data.user_detail.first_name,
          id: res.data.user_detail.id,
          last_name: res.data.user_detail.last_name,
          role: res.data.user_detail.role.name,
          state: res.data.user_detail.state,
          zipcode: res.data.user_detail.zipcode,
        });
        setStates(
          _.orderBy(
            _.filter(
              State.getAllStates(),
              (item: any) => item.countryCode == res.data.user_detail.country
            ),
            ["name"],
            ["asc"]
          )
        );
        setCity(
          _.orderBy(
            _.filter(
              City.getAllCities(),
              (item: any) =>
                item.stateCode == res.data.user_detail.state &&
                item.countryCode == res.data.user_detail.country
            ),
            ["name"],
            ["asc"]
          )
        );
        setSelectedCountry(res.data.user_detail.country);
        setEditUser(res.data.user_detail);
        setLoading(false);
      })
      .catch((err: any) => {
        openNotification(err?.response?.data?.full_message, "error");
        if (err.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return err.response;
      })
  }; 
  const handlelicensor = (values: any) => {
    setSaveModal(true)
    seteditedLicensorDetails(values)
  }
  
  const handleEdit = async (values: any) => {
    setBtnLoading(true);
    let selectedRole;
    if (!isNaN(+values.role)) {
      selectedRole = values.role;
    } else {
      const selected: any = adminRole.find(
        (item: any) => item.name == values.role
      );
      selectedRole = selected.id;

    }

    const data = {
      ...values,
      role_id: selectedRole,
    };
    delete data.role;

    await axios
      .patch(`${baseUrl}admin/user/update/${editUser.id}`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res) => {
        openNotification(res.data.full_message, "success");
        getUserList();
        setSaveModal(false)
        setEditmodal(false);
      })
      .catch((err: any) => {
        openNotification(err?.response?.data?.full_message, "error");
        if (err.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return err.response;
      })
      .finally(() => {
        setSaveModal(false)
        setEditmodal(false)
        setBtnLoading(false);
      });
  };
  const handleDelete = async () => {
    setBtnLoading(true);
    await axios
      .delete(`${baseUrl}admin/user/delete/${deleteId}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res) => {
        setDeleteModal(false);
        getUserList();
        setBtnLoading(false);
        form.resetFields();
        openNotification(res.data.full_message, "success");
      })
      .catch((err) => {
        openNotification(err?.response?.data?.full_message, "error");
        if (err.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return err.response;
      })
      .finally(() => {
        setBtnLoading(false);

      });
  };
  const [deleteId, setDeleteId] = useState("");
  const [searchLicensor, setSearchLicensor] = useState("");
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      filteredValue: [searchLicensor],
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a.first_name && b.first_name) {
          return a.first_name.localeCompare(b.first_name)
        }
      },
      onFilter: (value: any, record: any) => {
        if (record.first_name) {
          const dataName = record.first_name + " " + record.last_name
          if (dataName.toLowerCase().includes(value.toLowerCase())) {
            return record
          }
        }
      },
      width: 170,
      render: (key: any, row: any) => (
        <Row key={row.id} data-testid="title">
          {" "}
          {row.first_name} {row.last_name}
        </Row>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      width: 300,
      key: "email",
    },
    {
    title: "ROLE",
      dataIndex: "role",
      width: 180,
    },
    {
      title: "EDIT",
      dataIndex: "edit",
      width: 60,
      render: (key: any, row: any) => (
        <img
          height={34}
          width={34}
          src={EditIcon}
          style={{cursor: "pointer"}}
          data-testid="editBtn"
          onClick={() => {
            setEditmodal(true);
            getUserDetails(row.id);
          }}
        />
      ),
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      width: 80,
      render: (key: any, row: any) => (
        <img
          height={34}
          width={34}
          src={deleteIcon}
          style={{cursor: "pointer"}}
          data-testid="delteBtn"
          onClick={() => {
            setDeleteId(row.id);
            setDeleteModal(true);
          }}
        />
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setMultipleDelete(selectedRows)
    },
  };
  const handleMultipleDelete = async () => {
    const user_ids = multipleDelete.reduce((acc: any, currentValue: any) => {
      return [...acc, currentValue.id]
    }
      , [])
    const data = {
      user_ids
    };
    setBtnLoading(true);
    await axios
      .delete(`${baseUrl}admin/user/delete_multiple`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
        data
      })
      .then((res) => {
        setDeleteMultipleModal(false);
        getUserList();
        setBtnLoading(false);
        form.resetFields();
        setMultipleDelete([]);
        openNotification(res.data.full_message, "success");      
      })
      .catch((err) => {
        openNotification(err?.response?.data?.full_message, "error");
        if (err.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return err.response;
      })
      .finally(() => {
        setBtnLoading(false);
      });
}
  const [country, setCountry] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [city, setCity] = useState<any>([]);

  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedState, setSelectedState] = useState<any>("");
  const [selectedCity, setSelectedCity] = useState<any>("");
  useEffect(() => {
    setCountry(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStates(
      _.orderBy(
        _.filter(
          State.getAllStates(),
          (item: any) => item.countryCode == selectedCountry
        ),
        ["name"],
        ["asc"]
      )
    );
  }, [selectedCountry]);
  useEffect(() => {
    setCity(
      _.orderBy(
        _.filter(
          City.getAllCities(),
          (item: any) =>
            item.stateCode == selectedState &&
            item.countryCode == selectedCountry
        ),
        ["name"],
        ["asc"]
      )
    );
  }, [selectedState]);
  return (
    <>
      <AdminHeaderWeb />
      <Row className="mt-5">
        <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} xl={20} md={17} sm={16} xs={14}>
          <Row className="mb-1">
            <Col xs={24} lg={24} xl={23}>
              <Row
                justify="space-between"
                style={{ padding: "0px 12px" }}
                className="font_size23 font_500 mb-1"
              >
                <Col className="font_700">Licensor</Col>
                <Col >
                  <Row className="input-box">
                    <Input.Search
                      data-testid="searchBar"
                      placeholder="Search Licensor..."
                      onChange={(e) => setSearchLicensor(e.target.value)}
                    />
                  </Row>
                </Col>
                <Col style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                  {" "}
                  <Button
                    type="primary"
                    data-testid="add_new_user"
                    htmlType="submit"
                    className=" font_size16 primary-btn"
                    onClick={() => setAddNewUserModal(true)}
                  >
                    Add New Licensor
                  </Button>
                  {multipleDelete.length > 1 && <Button type="primary" onClick={() => setDeleteMultipleModal(!deleteMultipleModal)} className="font_size16 delte_btn">
                    Delete Selected
                  </Button>}
                </Col>
              </Row>
            </Col>
            <Col xl={1} />
          </Row>

          <Row
            justify="center"
            style={{ padding: "0px 12px" }}
          >
            <Col xs={24} sm={24} xl={23}>
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                //@ts-ignore
                columns={columns}
                dataSource={data}
                rowKey={"id"}
                loading={dataLoading}
                pagination={false}
                scroll={{ x: 700, y: 240 }}
                className="user_table "
              />
            </Col>
            <Col xl={1} />
          </Row>
          <Modal
            className="delete_modal"
            closable={false}
            visible={changeRoleModal}
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              Changes Roles
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              Do you want to change the current role?
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={() => setChangeRoleModal(false)}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={handleOk4}
                  className="ant-modal-btn-primary  mt-1 ml-1  width-120 height-40"
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            data-testid="deleteModal"
            visible={deleteMultipleModal}
            closable={false}
            className="delete_modal"
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              Delete Selections
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              You have selected {multipleDelete.length} users. <br />
              <span>Are you sure you want to delete selections?</span>
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={() => setDeleteMultipleModal(false)}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                  data-testid="deleteBtn"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  loading={btnLoading}
                  onClick={handleMultipleDelete}
                  className="ant-modal-btn-primary  mt-1 ml-1  width-120 height-40"
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            data-testid="add_new_user_modal"
            closable={false}
            visible={addNewUserModal}
            onCancel={handleCancel2}
            footer={false}
            className="edit_modal  Modal_form "
            centered
          >
            <Form
              autoComplete="off"
              form={form}
              layout="vertical"
              name="basic1"
              onFinish={handleNewUser}
            >
              <Row
                className="font_size26 font_500 mb-1"
                align="middle"
                justify="center"
              >
                <Col span={22} style={{ textAlignLast: "center" }}>
                  {" "}
                  Add New User
                </Col>{" "}
                <Col span={2}>
                  <CloseCircleOutlined
                    onClick={() => setAddNewUserModal(false)}
                    data-testid="closeNewUserModal"
                    translate="true"
                  />
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input FirstName!",
                      },
                    ]}
                  >
                    <Input className="height-42" bordered={false} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input LastName!",
                      },
                    ]}
                  >
                    <Input className="height-42" bordered={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="role"
                label="Job Role"
                rules={[
                  {
                    required: true,
                    message: "Please select role!",
                  },
                ]}
              >
                <Select
                  data-testid="new_job_role"
                  className="height-42"
                  placeholder="Select role"
                >
                  {adminRole.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input type="email" className="height-42" bordered={false} />
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
                initialValue={
                  "You have a new Portal with Green Apple Entertainment"
                }
                rules={[
                  {
                    required: true,
                    message: "Please Input Subject!",
                  },
                ]}
              >
                <Input className="height-42" placeholder="" bordered={false} />
              </Form.Item>
              <Form.Item
                label="Message"
                name="message"
                initialValue={
                  "This Portal (available via www.greenappleent.com) provides you picture sales performance reporting for titles that are under a license agreement."
                }
              >
                <TextArea
                  name="message"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>

              <Row className="font_size23 font_500 mt-1 mb-1">User Details</Row>
              <Row align="middle" justify="space-between">
                <Col span={24}>
                  <Form.Item
                    name="licensor_id"
                    label="Licensor Id"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Licensor Id!",
                      },
                    ]}
                  >
                    <Input
                      className="height-42"
                      placeholder=""
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  type="email"
                  data-testid="emailAnother"
                  className="height-42"
                  bordered={false}
                />
              </Form.Item>
              <Row className="font_size23 font_500 mb-1">
                Address Information
              </Row>
              <Form.Item
                label="Address"
                name="address"
              >
                <Input.TextArea placeholder="Enter your address" />
              </Form.Item>
              <Row align="middle" justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="country"
                    label="Country"
                  >
                    <Select
                      showSearch
                      data-testid="selectCountry"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="-Select Country-"
                      onChange={(e) => setSelectedCountry(e)}
                    >
                      {country.map((item: any) => (
                        <Option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    label="State"
                    name="state"
                  >
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="-Select State-"
                      onChange={(e) => setSelectedState(e)}
                    >
                      {states.map((item: any) => (
                        <Option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="city"
                    label="City"
                  >
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="-Select City-"
                      onChange={(e) => setSelectedCity(e)}
                    >
                      {city.map((item: any) => (
                        <Option key={item.name} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="zip_code"
                    label="Zip"
                  >
                    <Input
                      className="height-42"
                      placeholder="Enter the zip code"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" justify="end" className="mt-2">
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    data-testid="addNewUserBtn"
                    type="primary"
                    htmlType="submit"
                    className=" ant-modal-btn-primary width-150 font_size16"
                    style={{ width: "130px", height: "40px" }}
                    onClick={() => {console.log("values",userDetails)}}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Modal>
          <Modal
            data-testid="modal"
            closable={false}
            visible={editModal}
            destroyOnClose
            footer={false}
            className="edit_modal  Modal_form "
            centered
          >
            {loading ? (
              <Row align="middle" justify="center" data-testid="spinner">
                <Col>
                  <Spin size="large" />
                </Col>
              </Row>
            ) : (
              <Form
                layout="vertical"
                data-testid="editForm"
                autoComplete="off"
                form={form1}
                name="register"
                onFinish={handlelicensor}
              >
                <Row
                  className="font_size26 font_500 mb-1"
                  align="middle"
                  justify="center"
                >
                  <Col span={22}> Edit</Col>{" "}
                  <Col span={2}>
                    <CloseCircleOutlined
                      onClick={() => setEditmodal(false)}
                      translate="true"
                    />
                  </Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Input FirstName!",
                        },
                      ]}
                    >
                      <Input
                        type="name"
                        className="height-42"
                        placeholder=""
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Input LastName!",
                        },
                      ]}
                    >
                      <Input
                        type="name"
                        className="height-42"
                        placeholder=""
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input type="email" className="height-42" bordered={false} />
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Job Role"
                  rules={[
                    {
                      required: true,
                      message: "Please select role!",
                    },
                  ]}
                >
                  <Select
                    className="height-42"
                    placeholder="select role"
                    data-testid="new_job_role"
                  >
                    {adminRole.map((item: any) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row className="font_size23 font_500 mb-1">
                  Address Information
                </Row>
                <Form.Item
                  label="Address"
                  name="address"
                >
                  <Input.TextArea placeholder="Enter your address" />
                </Form.Item>
                <Row align="middle" justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name="country"
                      label="Country"
                    >
                      <Select
                        data-testid="country"
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="-Select Country-"
                        onChange={(e) => {
                          setSelectedCountry(e);
                          form1.setFieldsValue({
                            state: "",
                            city: "",
                          });
                        }}
                      >
                        {country.map((item: any) => (
                          <Option key={item.isoCode} value={item.isoCode}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="state"
                      label="State"
                    >
                      <Select
                        data-testid="state"
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="-Select State-"
                        onChange={(e) => {
                          setSelectedState(e);
                          form1.setFieldsValue({
                            city: "",
                          });
                        }}
                      >
                        {states.map((item: any) => (
                          <Option key={item.isoCode} value={item.isoCode}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name="city"
                      label="City"
                    >
                      <Select
                        data-testid="city"
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="-Select City-"
                        onChange={(e) => setSelectedCity(e)}
                      >
                        {city.map((item: any) => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="zipcode"
                      label="Zip"
                    >
                      <Input
                        className="height-42"
                        placeholder="Enter the zip code"
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" justify="end" className="mt-2">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className=" ant-modal-btn-primary  font_size16"
                      loading={btnLoading}
                      style={{ width: "130px", height: "40px" }}
                      data-testid="updateBtn"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            )}
          </Modal>
          <Modal
            className="delete_modal"
            data-testid="delete_modal"
            closable={false}
            visible={deleteModal}
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              Delete Licensor
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              Do you want to delete the selected user?
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={() => setDeleteModal(false)}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  loading={btnLoading}
                  onClick={handleDelete}
                  className="ant-modal-btn-primary mt-1 ml-1  width-120 height-40"
                  data-testid="deleteBtn"
                                  >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            data-testid="editSavedModal"
            className="editsave_modal"
            // title="Basic Modal"
            closable={false}
            visible={saveModal}
            footer={null}
            zIndex={9999}
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              Save Changes
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              Do you want to save the current changes?
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={()=> setSaveModal(false)}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  loading={btnLoading}
                  onClick={() => {handleEdit(editedLicensorDetails)}}
                  className="ant-modal-btn-primary  mt-1 ml-1  width-120 height-40"
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>    
          <Modal
            data-testid="SavedModal"
            className="editsave_modal"
            // title="Basic Modal"
            closable={false}
            visible={addLicensorModal}
            footer={null}
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              Save Changes
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              Do you want to save the current changes?
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={()=> setAddLicensorModal(false)}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  loading={btnLoading}
                  onClick={() => {createNewUser(userDetails)}}
                  className="ant-modal-btn-primary  mt-1 ml-1  width-120 height-40"
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>                
        </Col>
      </Row>
    </>
  );
};
export default UsersListWeb;
