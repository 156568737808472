import React from "react";

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";

import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";

const EditIcon = require("./Edit@3x.png");
// import { useHistory, useLocation } from "react-router-dom";
const ContentManagementWeb = (props: any) => {
  // const history = useHistory();
  console.log("props", props);
  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      <Row className="mt-5">
        <Col lg={5} xl={4} xxl={4} md={6} sm={7} xs={9}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} xl={20} xxl={20} md={17} sm={16} xs={14} >
          {" "}
          <Row
            // style={{ padding: "0px 18px" }}
            className="font_size23 font_700 mb-1"
          >
            <Col xs={24} lg={24} xl={23} style={{fontWeight: 'bold'}}>
              Content Management
            </Col>
            <Col xl={1} />
          </Row>

          <Row
            className="text_white font_size16 font_600"
            style={{ marginBottom: "8px" }}
          >
            Manage all your content
          </Row>
          <Row
            // justify="center"

            className="text_white p-5"
          >
            <Col xs={24} sm={24} xl={23} style={{
              backgroundColor: "#222222", padding: "18px",
              // minWidth: "600px",

              borderRadius: "18px"
            }}>

              {/* <Col xl={1}/> */}
              {/* <Col
              xs={24}
              sm={24}
              xl={23}
              style={{
               
                // height:"200px",
                backgroundColor: "#2D2D2D",
                // minWidth: "600px",

                borderRadius: "20px",
              }}
            > */}
              <Row
                className="p-1 font_600 text_white"
                style={{ backgroundColor: "#2C2D2E", borderRadius: "18px", width: "100%" }}
              >
                <Col span={21}> SECTION</Col>
                <Col span={3} > EDIT</Col>
              </Row>
              <Row className="p-1 font_size16" style={{ width: "100%" }}>
                {" "}
                <Col span={21}>Disclaimer</Col>{" "}
                <Col span={3}>
                  {" "}
                  <img
                    height={34}
                    width={34}
                    src={EditIcon}
                    data-testid="disclaimer"
                    style={{cursor: "pointer"}}
                    // onClick={showEditModal}
                    // onClick={()=> props.navigation.history.push("/ContentReportsWeb")}
                    // onClick={()=> history.push("/testtt@yopmail.com")}
                    onClick={() => (props.navigation.history.push("/admin/EditingDisclaimerWeb"))}
                  />
                </Col>
              </Row>
              <Row className="p-1 font_size16" style={{ width: "100%" }}>
                {" "}
                <Col span={21}>Earnings / Balance</Col>{" "}
                <Col span={3}>
                  {" "}
                  <img
                    data-testid="Earning"
                    height={34}
                    width={34}
                    src={EditIcon}
                    style={{cursor: "pointer"}}
                    // onClick={showEditModal} 
                    onClick={() => (props.navigation.history.push("/admin/EditingEarningWeb"))}
                  />
                </Col>
              </Row>
              <Row className="p-1 font_size16" style={{ width: "100%" }}>
                {" "}
                <Col span={21}>Privacy Policy</Col>
                <Col span={3}>
                  {" "}
                  <img
                    data-testid="privacy policy"
                    height={34}
                    width={34}
                    src={EditIcon}
                    style={{cursor: "pointer"}}
                    // onClick={showEditModal} 
                    onClick={() => (props.navigation.history.push("/admin/EditingPrivacyPolicyWeb"))}
                  />
                </Col>
              </Row>
              <Row className="p-1 font_size16" style={{ width: "100%" }}>
                {" "}
                <Col span={21}>Contact Support</Col>
                <Col span={3}>
                  {" "}
                  <img
                    data-testid="contact support"
                    height={34}
                    width={34}
                    src={EditIcon}
                    style={{cursor: "pointer"}}
                    // onClick={showEditModal} 
                    onClick={() => (props.navigation.history.push("/admin/EditingContactSupportWeb"))}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={1} />
          </Row>

        </Col>
        <Col span={1} />
      </Row>
    </>
  );
};

export default ContentManagementWeb;
