import React, { useEffect, useState } from "react";
import {
  MailOutlined,
  LockOutlined,
  SyncOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/forgot.css";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Divider,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";
import axios from "axios"
const logo = require("../../../components/src/image_Greenapple.png");
import { openNotification } from "../../../components/src/Notification.web";
import { UnAuthorized } from "../../../components/src/helper";

import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const {baseUrl, recaptcha} = urlRepacthaConfig()

const ResetPasswordWeb = (props: any) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const vToken: any = location.pathname.split("/").pop();
  useEffect(() => { 
    localStorage.removeItem("user_token")
  }, [])
  const onFinish = async (values: any) => {
    const data = {
      "new_password": values.password,
      "reset_token": vToken,
      "g-recaptcha-response": recaptchaRes
    }
    setLoading(true);
    await axios
      .post(`${baseUrl}change/password`, data)
      .then((res) => {
        setLoading(true);
        openNotification(res.data.full_message, "success")
        props.navigation.history.push("/")
        form.resetFields();
      })
      .catch((err) => {
        setLoading(false);
        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setRecaptchaRes('')
        resetCaptcha()
      })
  };
  const [recaptchaRes, setRecaptchaRes] = useState('')
  let captcha: any;

  const onChange = (value: any) => {
    setRecaptchaRes(value);
  }

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
  };

  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  }
  return (
    <>
    <Row className="login" style={{ marginTop: "70px", marginBottom:"0px" , }} />
      <Row  justify="center">
          <img
                src={logo}
                alt="logo"
                height="50px"
                width="230px"
                // onClick={() => props.navigation.history.push("/AccountWeb")}
              />
          </Row>
    <Row align="middle" justify="center">
     
      
      <Col sm={18} md={13} lg={10} xl={9} xxl={9} xs={20}>
        <Row
          align="middle"
          justify="center"
          className="mt-1"
          style={{ fontSize: "30px", fontWeight: 600, marginBottom: "20px" }}
        >
          Reset Password
        </Row>
        <Form
          className="login_form"
          style={{ marginTop: "70px" }}
          //   {...formItemLayout}
          autoComplete="off"
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }, {
              pattern: new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
              ),
              message:
                "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#",
            },]}
            hasFeedback

          >
            <Input
              data-testid="password"
              type="password"
              placeholder=" Enter New password"
              bordered={false}
              prefix={
                <LockOutlined
                  translate="true"
                  style={{
                    color: "#676666",
                    fontSize: "21px",
                    marginRight: "7px",
                  }}
                />
              }

            />
          </Form.Item>
          <Form.Item
            name="confirm"

            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "Your New Enter password and Re-enter New password do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input
              data-testid="confirmPassword"
              type="password"
              placeholder="Re-enter New password"
              bordered={false}
              prefix={
                <LockOutlined
                  translate="true"
                  style={{
                    color: "#676666",
                    fontSize: "21px",
                    marginRight: "7px",
                  }}
                />
              }
            />
          </Form.Item>



          <Form.Item>
            {/* @ts-ignore */}
            <ReCAPTCHA  ref={(r:any) => setCaptchaRef(r)}
              sitekey={recaptcha}
              onChange={onChange}
              theme="dark"/>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                borderRadius: "10px",
                backgroundColor: "#63A921",
                border: "0px",
                color: "black",
                fontSize: "21px",
                fontWeight: 600,
                padding: "5px",
                height: "50px",
                width: "230px",
              }}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
    </>

  );
};
export default ResetPasswordWeb;
 

