//@ts-nocheck
import {
  Col,
  Row,
  Switch,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import HeaderWeb from "../../../components/src/HeaderWeb";
import "../assets/contentreports.css";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import moment from "moment";
import parse from 'html-react-parser';

import { loginAuthorized, downloadFile } from '../../../components/src/helper';
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const {baseUrl, recaptcha} = urlRepacthaConfig()
const ContentReportsWeb = (props: any) => {
  const [selectedBy, setSelectedBy] = useState("Title");
  const [runBtnLosding, setRunBtnLosding] = useState(false);
  const [exportBtnLosding, setExportBtnLosding] = useState(false);
  const [grossEarning, setGrossEarning] = useState([]);
  const [titleName, setTitleName] = useState([]);
  const [EarningContent , setEarningContent] = useState("");
  const [dropdownoptions , setDropdownOptions] = useState([])
  const [form] = Form.useForm();
  const token: any = localStorage.getItem("user_token");
  const [runReportOnfinish, setRunReportOnfinish] = useState(false);
  const [selectedValue, setSelectedValue] = useState(''); 

  useEffect(() => {
    !token ? window.open("/", "_self") : null;
    handle_dropdwon(selectedBy);
  }, [selectedBy]);
  function handleErrorResponse(err, props) {
    if (err?.response?.status === 404 || err?.response?.status === 401) {
      loginAuthorized(props);
    }
  }
  const onChange = (checked: any) => {
    checked ? setSelectedBy("Title") : setSelectedBy("Platform");
    form.resetFields();
    setGrossEarning([]);
    setTitleName([]);
  };
  const run_report_title = (res) => {
    if (res.data.gross_earning.length === 0){
        console.log("insode if",)
        setTitleName([])
        setGrossEarning([0]);
    }

    else{
      console.log("inside else",res)
      setTitleName(res.data.gross_earning)
      setGrossEarning(res.data.gross_earning);
      }
    }
  const run_report_client = (res) => {
    if(res.data.gross_earning !== 0){
      console.log("resss=>",res)
      console.log("grrooosss",grossEarning)
      const formattedNumber = res.data.gross_earning.map((ele) => {
        return (
        {"ID": null,
        "Client_PlatformName": ele[0],
        "total_earnings": ele[1]
       } )
      }
      )
      setTitleName(formattedNumber)
      setGrossEarning(formattedNumber);              
    }
    else{
      console.log("insode else",res)
    setTitleName([])
    setGrossEarning([res.data.gross_earning]);
    }
  }
  console.log("grrooosss",grossEarning)

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
  };  
  const handle_dropdwon = (selected) => {  
    let apiUrl = "";
    if (selected === "Title") {
      apiUrl = `${baseUrl}user/get_picture_title`;
    } else {
      apiUrl = `${baseUrl}user/get_client_info`;
    }
  
    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          accessToken: token,
        },
      })
      .then((res) => {
        const options = res?.data?.picture_title || res?.data?.Client_PlatformName || [];
        console.log("option are ",options)
        setDropdownOptions(options);
      })
      .catch((err) => {
        setDropdownOptions([]);
      });
  };
  const fun1 = () => {
    axios.get(`${baseUrl}user/earning_details`,{
    headers: {
                "Content-Type": "application/json;charset=UTF-8",
                accessToken: token,
              },
  }).then((res)=>{
    setEarningContent(res.data)
  }).catch((err)=>{
  })
}
  useEffect(() => { 
    fun1()
}, []);
const  onFinish = async (values: any) => {
  if (values.run_report) {
    if (selectedBy === "Title") {
      setRunReportOnfinish(true);
      setRunBtnLosding(true);
      const data = {
        liecensor_role: values.liecensor_role,
        end_date: moment(values.end_date).format("DD-MM-YYYY"),
        from_date: moment(values.from_date).format("DD-MM-YYYY"),
        picture_title: values.picture_title,
      };
  await axios
    .get(`${baseUrl}run/report/title`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        accessToken: token,
      },
      params: data,
    })
    .then((res) => {
      console.log("ress",res)
      run_report_title(res)
    })
    .catch((err) => {
      openNotification(
        err?.response?.data?.errors[0].full_message,
        "error"
      );
      err?.response?.status == 404 || 401 ? loginAuthorized(props) : "";
    })
    .finally(() => {
      setRunBtnLosding(false);
    });
} else {
  setRunReportOnfinish(true);
  setRunBtnLosding(true);
  const data = {
    platform_name: values.platform_name,
    liecensor_role: values.liecensor_role,
    end_date: moment(values.end_date).format("DD-MM-YYYY"),
    from_date: moment(values.from_date).format("DD-MM-YYYY"),
  };

  await axios
    .get(`${baseUrl}run/report/client`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        accessToken: token,
      },
      params: data,
    })
    .then((res) => {
      console.log("resss",res)
      run_report_client(res)
    })
    .catch((err) => {
      openNotification(
        console.log("err",err),
        err?.response?.data?.errors[0].full_message,
        // "error"
      );
      err?.response?.data?.errors[0].full_message
        ? window.open("/", "_self")
        : null;
      handleErrorResponse(err, props);
    })
    .finally(() => {
      setRunBtnLosding(false);
    });
}
} else {
if (selectedBy === "Title") {
  setExportBtnLosding(true);
  setRunReportOnfinish(false);
  const data = {
    licensor_name: values.liecensor_role,
    end_date: moment(values.end_date).format("DD-MM-YYYY"),
    from_date: moment(values.from_date).format("DD-MM-YYYY"),
    program_title: values.picture_title,
  };
  await axios
    .get(`${baseUrl}export/title/report`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        accessToken: token,
      },
      params: data,
    })
    .then((res) => {
      res.data.full_message
        ? openNotification(res.data.full_message, "error")
        : "";
      res.data.url ? downloadFile(res.data.url) : "";
      setGrossEarning(grossEarning);
    })
    .catch((err) => {
      openNotification(
        err?.response?.data?.errors[0].full_message,
        "error"
      );
      err?.response?.data?.errors[0].full_message
        ? window.open("/", "_self")
        : null;
      err.response.status == 404 || 401 ? loginAuthorized(props) : "";
    })
    .finally(() => {
      setExportBtnLosding(false);
    });
} else {
  setRunReportOnfinish(false);
  setExportBtnLosding(true);
  const data = {
    platform_name: values.platform_name,
    end_date: moment(values.end_date).format("DD-MM-YYYY"),
    from_date: moment(values.from_date).format("DD-MM-YYYY"),
  };
  await axios
    .get(`${baseUrl}export/client/report`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        accessToken: token,
      },
      params: data,
    })
    .then((res) => {
      res.data.url ? downloadFile(res.data.url) : "";
    })
    .catch((err) => {
      openNotification(
        err?.response?.data?.errors[0].full_message,
        "error"
      );
      err?.response?.data?.errors[0].full_message
        ? window.open("/", "_self")
        : null;
      err.response.status == 404 || 401 ? loginAuthorized(props) : "";
    })
    .finally(() => {
      setExportBtnLosding(false);
    });
}
}

};

  const dateFormatList = "MM/DD/YYYY";
  function disabledDate(current: any) {
    return current && current > moment().endOf("day");
  }
  console.log("gross_eraning",titleName)
  console.log("title",grossEarning)
  return (
    <>
      <HeaderWeb />
      <Row className="first_row font_900">
            Earnings Reports
            </Row>
            <Row>{" "}</Row>
      <Row justify="center" className="pt-1">
     
        <Col span={16}>
          <Row justify="space-between">
            <Col>
              <h2 className="text_white font_900" style={{cursor: "default"}}>
                Picture Performance Data - By {selectedBy}
              </h2>
            </Col>
            <Col>
              <span
                className={`${
                  selectedBy == "Platform" ? "active_menu" : ""
                } pr-2`}
              >
                Platform
              </span>
              <Switch size="default" defaultChecked onChange={onChange} />
              <span
                className={`${selectedBy == "Title" ? "active_menu" : ""} pl-2`}
              >
                Title
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                name="basic"
                onFinish={(values: any) => onFinish(values)}
                autoComplete="off"
                form={form}
              >
                <Row justify="space-between" className="mb-1 mt-1">
                  <Col xs={24} sm={24} md={12} className="my_container">
                    <Form.Item
                      label={
                        selectedBy == "Title"
                          ? "PROGRAM TITLE"
                          : "EARNING PLATFORM"
                      }
                      name={
                        selectedBy == "Title"
                          ? "picture_title"
                          : "platform_name"
                      }
                      rules={
                        selectedBy == "Title"
                          ? [
                              {
                                required: true,
                                message: "Please Add Program Title!",
                              },
                            ]
                          : [
                              {
                                required: true,
                                message: "Please Add Platform Name!",
                              },
                            ]
                      }
                    >
                    <Select
                      data-testid="program-title-select"
                      showSearch
                      placeholder="Select an option"
                      className="custom-select"
                      options={dropdownoptions.map((option) => ({ value: option, label: option }))}
                      value={selectedValue}
                      onChange={(value) => {
                        handleDropdownChange(value);
                      }}
                      >
                        <Option data-test-id="option_test_id" >Select an Option</Option>
                    </Select>
                    </Form.Item>
                  </Col>
        
                  <Col xs={24} sm={24} md={12}>
                    <Row justify="end">
                      <Form.Item
                        label="START"
                        name="from_date"
                        rules={[
                          {
                            required: true,
                            message: "Please Add Start Date!",
                          },
                        ]}
                      >
                        <DatePicker
                          className="my-datepicker"
                          format={dateFormatList}
                          clearIcon={false}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-1"style={{justifyContent: "end"}} >
                  <Col xs={24} sm={24} md={18}>
                    <Row justify="end">
                      <Form.Item
                        label="END"
                        name="end_date"
                        rules={[
                          {
                            required: true,
                            message: "Please Add End Date!",
                          },
                        ]}
                      >
                        <DatePicker
                          className="my-datepicker"
                          format={dateFormatList}
                          clearIcon={false}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row className="gray_back" >
                  <Col span={12}>
                    <Row justify="center">{`${selectedBy}` === "Title" ? "EARNING PLATFORM" : "PROGRAM TITLE" }</Row>
                  </Col>
                  <Col span={12}>
                    <Row justify="center">EARNINGS</Row>
                  </Col>
                  <Divider />
                 
                  <Col span={12}  data-testid="reportTitle">
                    {titleName.map((e) => {
                       return <Row justify="center" style={{marginBottom: "10px"}} className="title_display" data-testid="reportTitle">{e ? e?.Client_PlatformName : e}</Row>
                    })}
                  </Col>
                  {
                    titleName !== [] ? "" : <Row justify="center" style={{marginBottom: "10px"}} className="title_display" data-testid="reportTitle">Total</Row>
                  }
                  {runReportOnfinish || grossEarning !== "" ? (
                    <Col span={12} >
                      {grossEarning?.map((e) => {
                        return  <Row justify="center" style={{marginBottom: "10px"}} className="title_display"> ${e?.total_earnings ? parseFloat(e.total_earnings).toFixed(2) : 0}</Row>
                      })}
                    </Col>
                  ) : (
                    <Col span={12} />
                  )}


                  </Row>
                <Row className="pt-3" justify="end">
                  <Form.Item name="run_report">
                    <Button
                      className="width-150"
                      loading={runBtnLosding}
                      type="primary" 
                      htmlType="submit"
                      onClick={() =>
                        form.setFieldsValue({
                          run_report: true,
                          exportReport: false,
                        })
                      }
                    >
                      Run Report
                    </Button>
                  </Form.Item>
                  <Form.Item name="export_report">
                    <Button
                      loading={exportBtnLosding}
                      className="ml-1 width-150"
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        form.setFieldsValue({
                          export_report: true,
                          run_report: false,
                        })
                      }
                    >
                      Export Report
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
          <Divider />
          <Row>
            <h3 className="text_white font_700">Earning Details</h3>
            <div>
                  {EarningContent ? parse(`${EarningContent?.earning_content?.detail}`) : "No Earning Content Found"}
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ContentReportsWeb;