export default  function urlRepacthaConfig ()  {

let baseUrl: any = "";
let recaptcha: any = "";

if (window.location.href.includes("prod")){
  baseUrl = process.env.REACT_APP_PROD_API_PATH
  recaptcha = process.env.REACT_APP_STAGE_recaptcha_key  

  
}
else if(window.location.href.includes("stage")) {
  baseUrl = process.env.REACT_APP_STAGE_API_PATH
  recaptcha = process.env.REACT_APP_STAGE_recaptcha_key
}
else if(window.location.href.includes("uat")) {
  baseUrl = "https://toddwarrengae-88612-ruby.b88612.uat.eastus.az.svc.builder.ai/"
  recaptcha = process.env.REACT_APP_STAGE_recaptcha_key
}
else{
  baseUrl = process.env.REACT_APP_baseUrl
  recaptcha = process.env.REACT_APP_recaptcha_key   

}


return {baseUrl, recaptcha}



}

