import React, { useState, useEffect } from "react";
import { mailUsAt, callUsOn } from "./assets";
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  Menu,
  InputNumber,
  Collapse,
  Image,
  Typography
} from "antd";
import HeaderWeb from "../../../components/src/HeaderWeb";
import "../assets/userSetting.css";
import {
  SettingFilled,
  SettingOutlined,
  UserOutlined,
  MailOutlined,
  LockOutlined,
  LogoutOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import axios from "axios"
import { openNotification } from "../../../components/src/Notification.web";
import { UnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const {baseUrl} = urlRepacthaConfig()

const { SubMenu } = Menu;
const { Paragraph } = Typography;
const UserSettingsWeb = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const [contactUs, setContactUs] = useState<any>()

  const { Panel } = Collapse;
  //   const [collapsed, setCollapsed] = useState(false);
  //   const toggleCollapsed = () => {
  //     setCollapsed(!collapsed);
  //   };
  const token: any = localStorage.getItem("user_token")

  useEffect(() => {
    getDisclaimer()

  }, [])
  useEffect(() => {
    getContact()
  }, [])
  
  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      "old_password": values.old_password,
      "new_password": values.password
    }
    await axios
      .post(`${baseUrl}update`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "accessToken": token,
        },
      })
      .then((res: any) => {
        openNotification(res.data.full_message, "success")
        form.resetFields();
      })
      .catch((err: any) => {
        setLoading(false);
        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);
      })
  };


  const getDisclaimer = async () => {
    await axios
      .get(`${baseUrl}privacy/get/data`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => {
          setPrivacyPolicy(res.data.info.description)
        //  setDisclaimer(res.data.disclaimer_content[0].detail); 
      })
      .catch((err: any) => { 
        // err.response.status == 404||401 ? UnAuthorized(props) : ""
        // openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);
      })
      
  };
  const getContact = async () => {
    await axios
      .get(`${baseUrl}contact/get/data`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => {
        const PhoneNumber = res?.data?.info?.phone;
        const contact_email = res?.data?.info?.email;
        setContactUs({email: contact_email , phone: PhoneNumber})
      })
      .catch((err: any) => { 
        // err.response.status == 404||401 ? UnAuthorized(props) : ""
        // openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);
      })
  };


  return (
    <>
      <HeaderWeb />
      <Row justify="center" align="middle" className="mainRow">
        <Col xs={21} sm={16} md={13} lg={12} xl={12} xxl={11} className="changePassDrop">
          <Row className="title">User Settings</Row>

          <Menu
            className="main_menu table_body border_radius_2 text_white pt-2em pb-2em "
            mode="inline"
          >
            <SubMenu
              key="sub1"
              title={
                <Row align="middle">
                  <Col>
                    <UserOutlined
                      translate="yes"
                      className="userOutline text_white "
                    />{" "}
                  </Col>{" "}
                  <Col>
                    {" "}
                    <Row className="text_white font_size16 font_500 "  data-testid="changePassword" >Change Password</Row>
                  </Col>
                </Row>
              }
            >
              <Menu.Item className="menuItem1" key="5" style={{cursor: "default"}}>
                <Form
                  className="profile_form"
                  layout="vertical"
                  style={{cursor: "default"}}
                  autoComplete="off"
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >


                  <Form.Item
                    data-testid="oldpassword"
                    name="old_password"
                    label="Enter Current Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="input_login"
                      type="password"
                      placeholder="Password"
                      bordered={false}
                      iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}

                    />
                  </Form.Item>



                  <Form.Item
                    data-testid="newpassword"
                    name="password"
                    label="Enter New Password"
                    rules={[{ required: true, message: "Please input your Password!" }, {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                      ),
                      message:
                        "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#",
                    },]}
                    hasFeedback
                  >
                    <Input.Password
                      className="input_login"
                      type="password"
                      placeholder="Password"
                      bordered={false}
                      iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Re-enter new password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Your New Enter password and Re-enter New password do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="input_login"
                      type="password"
                      placeholder="Password"
                      bordered={false}
                      iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}

                    />
                  </Form.Item>
                  <Form.Item className="no_border ">
                    <Row justify="end"  >
                      <Button loading={loading} data-testid="save" className="save_btn" type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
              </Menu.Item>
            </SubMenu>
          </Menu>

          <Menu
            className="main_menu table_body border_radius_2 text_white pt-2em pb-2em "
            mode="inline"
          >

            <SubMenu
              key="sub1"
              title={
                <Row align="middle">
                  <LockOutlined translate="yes" className="LockOutlined" />{" "}
                  <Row className="text_white font_size16 font_500" data-testid="privacyPolicy" >Privacy Policy</Row>
                </Row>
              }
            >
              <Form>
                <Form.Item data-testid="privacy"   style={{ marginLeft: 20, marginRight: 10, overflow: 'auto', height: 200 }} >
                  <Typography.Paragraph  data-testid="privacysample" style={{ color: '#C0C0C0' }}>
                    {privacyPolicy ? privacyPolicy : 'No Policy Added'}
                  </Typography.Paragraph>
                </Form.Item>
              </Form>

            </SubMenu>

          </Menu>

          <Menu
            className="changePassDrop border_radius_2 table_body text_white"
            mode="inline"
          >
            <SubMenu
              key="sub1"
              title={
                <Row align="middle">
                  <MailOutlined translate="yes" className="MailOutlined" />{" "}
                  <Row data-testid="Contact Help Support" className="text_white font_size16 font_500">
                    Contact Help Support
                  </Row>
                </Row>
              }>
              <Menu.Item className="menuItem1" key="5" style={{cursor: "default"}}>
                <Form
                  className="profile_form"
                  layout="vertical"
                  autoComplete="off"
                  form={form}
                  style={{cursor: "default"}}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >
              <Row gutter={{ xs: 8 , sm: 16, md: 24 , lg: 54 }}  justify = 'space-between' style={{ display: 'flex'   , margin: 'auto' , width: '70%' }}>   
              <Col className="gutter-row">
                <Row style={{ pointerEvents: 'none' ,cursor: 'default'}}>
                  <Image width={17}  src={callUsOn}/>
                  <Typography style={{ color: '#808080' , fontSize: 16 , paddingLeft: 15}}> Call us on</Typography>
                  </Row >
                  <Row data-testid="support"><Typography style={{ color: 'white', fontSize: 16}}>{contactUs?.phone}</Typography></Row>

              </Col>
              <Col className="gutter-row">
              <Row style={{ pointerEvents: 'none' ,cursor: 'default'}}>
                  <Image width={17}  src={mailUsAt}/>
                  <Typography   style={{ color: '#808080' , fontSize: 16 , paddingLeft: 15 }}> Email us at</Typography>
                </Row>
                <Row  data-testid="email_support" >              
                 <Typography style={{ color: 'white' ,fontSize: 16 }}>{contactUs?.email}</Typography></Row>
                 </Col>             
              </Row>
                </Form>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
    </>
  );
};

export default UserSettingsWeb;
