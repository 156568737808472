import React, { useEffect, useState } from 'react'
import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import { Col, Row,  Select, Button, Form, Input, Modal,  Avatar,Spin } from "antd";
import { CheckOutlined, CloseOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import AdminHeaderWeb from '../../../components/src/AdminHeaderWeb';
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { openNotification } from "../../../components/src/Notification.web";
import _ from "lodash";
import { AdminUnAuthorized } from '../../../components/src/helper';
import urlRepacthaConfig from '../../email-account-login/src/urlConfig';

const {baseUrl} =  urlRepacthaConfig();
type RequiredMark = boolean | 'optional';

const { Option } = Select;

const AdminUpdateProfileWeb = (props: any) => {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');
    const [country, setCountry] = useState<any>([]);
    const [states, setStates] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<any>("");
    const [editUser, setEditUser] = useState<any>('');
    const [selectedState, setSelectedState] = useState<any>("");
    const [selectedCity, setSelectedCity] = useState<any>("");
    const [visible, setVisible] = useState(false);
    const [formData, setFormData] = useState({})
    const [btnLoading, setBtnLoading] = useState(false)
    const [checkFormFailed, setCheckFormFailed] = useState<any>(null)
    const [fileList, setFileList] = useState<any>([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        }
    ])


    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const token: any = localStorage.getItem("access_token");
    const userId: any = localStorage.getItem("user_admin_id");
    const [updateProfile, setUpdateProfile] = useState("")
 
    const submitForm = (values: any) => {
      
setUpdateProfile(values);
   
        setVisible(true)
      

    }

    const onFinishFailed = (errorInfo: any) => {
        setCheckFormFailed(errorInfo.errorFields.length)
    };

    const handleUpdateProfile = async (values: any) => {
 
        await axios.put(`${baseUrl}bx_block_admin/admin_users/${userId}`, updateProfile, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                token: token,
            },
        })
            .then((res) => { 
                setBtnLoading(false)
                setVisible(false)
                getUserDetails(localStorage.getItem("user_admin_id"))

                openNotification(res.data.full_message, "success")

            })
            .catch((err: any) => {
                // openNotification(err?.response?.data?.errors[0]?.full_message, "error")
                err.response.status == 404 || 401 ? AdminUnAuthorized(props) : ""
                openNotification(err?.response?.data?.full_message, "error")
                setBtnLoading(false)
                setVisible(false)
            }).finally(() => {
                // setStatus('')

            })
    }
 
    const getUserDetails = async (id: any) => {
     
        console.log("id", id)
        setLoading(true);
        await axios
            .get(`${baseUrl}admin/user/${id}`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    token: token,
                },
            })
            .then((res) => {
                console.log("response--", res)
                // setAvatar(res.data.user_detail.first_name.charAt(0).toUpperCase());

                form.setFieldsValue({
                    address: res.data.user_detail.address,
                    city: res.data.user_detail.city,
                    country: res.data.user_detail.country,
                    // email: res.data.user_detail.email,
                    first_name: res.data.user_detail.first_name,
                    id: res.data.user_detail.id,
                    last_name: res.data.user_detail.last_name,
                    // role: res.data.user_detail.role.name,
                    state: res.data.user_detail.state,
                    zipcode: res.data.user_detail.zipcode,

                });

                setCheckFormFailed(0)
                setStates(
                    _.orderBy(
                        _.filter(
                            State.getAllStates(),
                            (item: any) => item.countryCode == res.data.user_detail.country
                        ),
                        ["name"],
                        ["asc"]
                    )
                );
                setCity(
                    _.orderBy(
                        _.filter(
                            City.getAllCities(),
                            (item: any) =>
                                item.stateCode == res.data.user_detail.state &&
                                item.countryCode == res.data.user_detail.country
                        ),
                        ["name"],
                        ["asc"]
                    )
                );
                setSelectedCountry(res.data.user_detail.country)
                setEditUser(res.data.user_detail)
                setLoading(false);
                // setBtnLoading(false);
            })
            .catch((err: any) => {
                // openNotification(err?.response?.data?.errors[0]?.full_message, "error")
                err.response.status == 404 || 401 ? AdminUnAuthorized(props) : ""
                openNotification(err?.response?.data?.full_message, "error")
            }).finally(() => {
                // setStatus('')
                setLoading(false)

            })

    }


    useEffect(() => {
        getUserDetails(localStorage.getItem("user_admin_id"))
    }, [])

    useEffect(() => {
        setCountry(Country.getAllCountries());
        // setStates(State.getAllStates())
        // setCity(City.getAllCities())

    }, []);

    useEffect(() => {
        setStates(
            _.orderBy(
                _.filter(
                    State.getAllStates(),
                    (item: any) => item.countryCode == selectedCountry
                ),
                ["name"],
                ["asc"]
            )
        );

        // form1.setFieldsValue({
        //   state:''
        // })
    }, [selectedCountry]);

    useEffect(() => {
        setCity(
            _.orderBy(
                _.filter(
                    City.getAllCities(),
                    (item: any) =>
                        item.stateCode == selectedState &&
                        item.countryCode == selectedCountry
                ),
                ["name"],
                ["asc"]
            )
        );
    }, [selectedState]);

    // const showModal = () => {

    //     // setHandleUserName(name)
    //     // setHandleUserId(id)
    //     setVisible(true)

    // };


    const handleOk = () => {
        setBtnLoading(true)
        // submitUpdateForm(formData)

    };

    const handleCancel = () => {
        setVisible(false)
    };


    const handleChange = ({ fileList }: any) => {
        console.log("FileList--", fileList)
        setFileList(fileList)

    };

    // useEffect(() => {
    //     console.log("useEffect",fileList)
    // }, [fileList])


    const uploadButton = (
        <div>
            <PlusOutlined translate />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <AdminHeaderWeb />
            {/* <Row style={{height:"65px"}}/> */}
            {
                loading ? (<Spin data-testid="spinner"/>) : (
                    <>
                        <Row className="" align="middle" justify='center' >
                            {/* <Col lg={5} xl={4} md={6} sm={7} xs={10}>
                    <SideBarWeb props={props}/>
                    </Col>{" "} */}
                            <Col
                                //   lg={18}
                                //   xl={20}
                                //   md={17}
                                //   sm={16}
                                span={14}

                                className="admin-requestlist-web">
                                <Row className='admin-detail-top-btn-sec'>
                                    <button className='top-btn1'
                                        onClick={() =>
                                            props.navigation.history.push("/admin/AdminUpdateProfileWeb")
                                        }
                                        style={{cursor: "pointer"}}
                                    >Update Profile</button>
                                    <button className='top-btn2'
                                        onClick={() =>
                                            props.navigation.history.push("/admin/AdminSettingWeb")
                                        }
                                        style={{cursor: "pointer"}}
                                    >Change Settings</button>
                                </Row>
                                <p className="main2-head-txt-admin-home "> Admin Details</p>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    // initialValues={{ requiredMarkValue: requiredMark }}
                                    // onValuesChange={onRequiredTypeChange}
                                    // requiredMark={requiredMark}
                                    onFinish={submitForm}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24} className='mb-1'>
                                            {/* <Upload
                                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={handleChange}
                                        >
                                        {fileList.length >= 1 ? null : uploadButton}
                                    </Upload> */} 
                                            <Avatar size="large" alt="Name" style={{ color: 'Black', backgroundColor: '#fff',fontSize:'20px' }} >
                                                {form.getFieldValue('first_name')?.charAt(0).toUpperCase()}
                                                {form.getFieldValue('last_name')?.charAt(0).toUpperCase()}
                                            </Avatar>
                                        </Col>
                                    </Row>

                                    <Row gutter={32}>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name="first_name" label="First Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter First Name",
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="Enter your first name" className='admin-update-profile-form' />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label="Last Name"
                                                name="last_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter Last Name",
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="Enter your last name" className='admin-update-profile-form' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item name="company_name" label="Company Name"  >
                                                <Input placeholder="Enter your Company name"  className='admin-update-profile-form' />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Col>
                                        <p className="main2-head-txt-admin-home "> Address information</p>
                                    </Col>

                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item name="address" label="Address"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Address",
                                                    }
                                                ]}
                                            >
                                                <Input.TextArea placeholder="Enter your Address" className='admin-update-profile-form' maxLength={500} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>

                                            <Form.Item name="country" label="Country"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please select country",
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(input: any, option: any) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    placeholder="-Select Country-"

                                                    allowClear
                                                    className='admin-update-profile-form-slect'
                                                    onChange={(e) => setSelectedCountry(e)}
                                                >
                                                    {country.map((item: any) => (
                                                        <Option key={item.isoCode} value={item.isoCode}>
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item name="state" label="State"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter select state",
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(input: any, option: any) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                
                                                    placeholder="-Select State-"
                                                    allowClear
                                                    onChange={(e) => setSelectedState(e)}
                                                    className='admin-update-profile-form-slect'
                                                >
                                                    {states.map((item: any) => (
                                                        <Option key={item.isoCode} value={item.isoCode}>
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item name="city" label="City"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please select city",
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(input: any, option: any) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    placeholder="-Select City-"
                                                    allowClear
                                                    className='admin-update-profile-form-slect'
                                                    onChange={(e) => setSelectedCity(e)}
                                                >
                                                {city.map((item: any) => (
                                                        <Option key={item.name} value={item.name}>
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item name="zipcode" label="Zip"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter zip",
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="Enter the Zip Code" className='admin-update-profile-form' />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item>
                                        <Button type="primary" className='admin-update-profile-form-btn' htmlType="submit"
                                            // onClick={
                                            //     () => !form
                                            //         .getFieldsError()
                                            //         .filter(({ errors }) => errors.length).length && showModal()
                                            // }
                                        >Save</Button>
                                    </Form.Item>


                                </Form>
                            </Col>
                        </Row>

                        <Modal
                            className='admin-settings-pwd-update-modal'
                            visible={visible}
                            data-testid="updateModal"
                            //   title="Title"
                            onOk={handleOk}
                            onCancel={handleCancel}
                            footer={[
                                <Row className='admin-settings-pwd-update-modal-footer-sec' justify='center'>
                                    <Button key="back" onClick={handleCancel} className="admin-settings-pwd-update-modal-footer-sec-cancel-btn">
                                        Cancel
                                    </Button>
                                    <Button key="submit" type="primary" loading={btnLoading} onClick={handleUpdateProfile} className="admin-settings-pwd-update-modal-footer-sec-save-btn">
                                        Yes
                                    </Button>
                                </Row>
                            ]}
                        >
                            <p className='admin-settings-pwd-update-modal-head'>Update Profile</p>
                            <p className='admin-settings-pwd-update-modal-txt'>Do you want to update the changes that you've made in your profile?</p>

                        </Modal>
                    </>
                )
            }


        </>
    )
}

export default AdminUpdateProfileWeb


