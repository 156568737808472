import { Col, Row} from "antd";
import { NavLink } from "react-router-dom";
import React from "react";


const analytics = require("./Analytics@3x.png");
const Users = require("./Users@3x.png");
const Request = require("./Requests@3x.png");
const Permission = require("./Permissions@3x.png");
const ContentManagement = require("./Content Management Selected@3x.png");
import "../../blocks/user-profile-basic/src/ContentReportsWeb";
const SideBarWeb = (props: any) => {
  const currentPath: any = props.props.navigation.match.path;
 

  return (
    <>
      {" "}
      {/* <Row style={{minWidth:"178px"}}> */}
      <Row style={{ padding: "6px 0px 14px 10px " }}>
        <NavLink
          to="/admin/Analytics3Web"
          activeClassName="active"
          className=" text_white  inactive"
          exact={true}
        >
          <Row align="middle">
            {currentPath == "/admin/Analytics3Web" ? (
              <>
                <Row align="middle" justify="start" >
                  <Col span={9}>
                    {" "}
                    <img
                      className="activeSidebarManu"
                      src={analytics}
                      alt="logo"
                      height="16px"
                      width="15px"
                    // style={{ marginRight: "14px" }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={14}>Analytics</Col>
                </Row>
             
                <Row style={{ width: "100%" }}>
                  <hr
                    className="activeSidebarManu"
                    style={{
                      width: "170px",

                      marginBlockEnd: "0em",
                      marginBlockStart: "0em",
                      marginBottom: "0px",
                      marginTop: "6px",
                      marginInlineStart: "initial",
                    }}
                  />
                </Row>
              </>
            ) : (
              <Row align="middle" justify="start">
                <Col span={9}>
                  {" "}
                  <img
                    src={analytics}
                    alt="logo"
                    height="16px"
                    width="15px"
                  // style={{ marginRight: "14px" }}
                  />
                </Col>
                <Col span={1} />
                <Col span={14}>Analytics</Col>{" "}
              </Row>
            )}
          </Row>
          {/* <LineOutlined translate/> */}
        </NavLink>
      </Row>
      <Row style={{ padding: "6px 0px 14px 10px " }}>
        <NavLink
          to="/admin/UsersListWeb"
          activeClassName="active"
          className="text_white inactive"
          exact={true}
        >
          <Row align="middle">
            {currentPath == "/admin/UsersListWeb" ? (
              <>
                <Row
                  align="middle"
                  justify="start"
                // style={{ marginBottom: "6px" }}
                >


                  <Col span={10}>
                    <img
                      className="activeSidebarManu"
                      src={Users}
                      alt="logo"
                      height="16px"
                      width="15px"
                    // style={{ marginRight: "14px" }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={10}>Licensor</Col>
                </Row>
                {/* <Divider
                  style={{ margin: "14px 123px -10px -3px" }}
                  className="activeSidebarManu"
                /> */}
                <Row style={{ width: "100%" }}>
                  <hr
                    className="activeSidebarManu"
                    style={{
                      width: "170px",

                      marginBlockEnd: "0em",
                      marginBlockStart: "0em",
                      marginBottom: "0px",
                      marginTop: "6px",
                      marginInlineStart: "initial",
                    }}
                  />
                </Row>
              </>
            ) : (
              <Row align="middle" justify="start">
                <Col span={10}>
                  {" "}
                  <img
                    src={Users}
                    alt="logo"
                    height="16px"
                    width="15px"
                  // style={{ marginRight: "14px" }}
                  />
                </Col>
                <Col span={1} />
                <Col span={10}>Licensor</Col>{" "}
              </Row>
            )}
          </Row>
        </NavLink>
      </Row>
      <Row style={{ padding: "6px 0px 14px 10px " }}>
        <NavLink
          to="/admin/RequestListWeb"
          activeClassName="active"
          className="text_white inactive"
          exact={true}
        >
          <Row align="middle">
            {currentPath == "/admin/RequestListWeb" ? (
              <>
                <Row align="middle" justify="start">
                  <Col span={10}>
                    <img
                      className="activeSidebarManu"
                      src={Request}
                      alt="logo"
                      height="16px"
                      width="15px"
                    // style={{ marginRight: "14px" }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={10}>Request</Col>
                </Row>
                {/* <Divider
                  style={{ margin: "14px 106px -10px -3px" }}
                  className="activeSidebarManu"
                /> */}
                <Row style={{ width: "100%" }}>
                  <hr
                    className="activeSidebarManu"
                    style={{
                      width: "170px",

                      marginBlockEnd: "0em",
                      marginBlockStart: "0em",
                      marginBottom: "0px",
                      marginTop: "6px",
                      marginInlineStart: "initial",
                    }}
                  />
                </Row>
              </>
            ) : (
              <Row align="middle" justify="start">
                <Col span={10}>
                  <img
                    src={Request}
                    alt="logo"
                    height="16px"
                    width="15px"
                  // style={{ marginRight: "14px" }}
                  />
                </Col>
                <Col span={1} />
                <Col span={10}>Request</Col>{" "}
              </Row>
            )}
            {/* {currentPath == "/admin/RequestListWeb" ? (
              <img
                className="activeSidebarManu"
                src={Request}
                alt="logo"
                height="16px"
                width="15px"
                style={{ marginRight: "14px" }}
              />
            ) : (
              <img
                src={Request}
                alt="logo"
                height="16px"
                width="15px"
                style={{ marginRight: "14px" }}
              />
            )}
            Request */}
          </Row>
        </NavLink>
      </Row>
      <Row style={{ padding: "6px 0px 14px 10px " }}>
        <NavLink
          to="/admin/PermissionWeb"
          activeClassName="active"
          className="text_white inactive"
          exact={true}
        >
          <Row align="middle">
            {currentPath == "/admin/PermissionWeb" ? (
              <>
                <Row align="middle" justify="start">
                  <Col span={4}>
                    <img
                      className="activeSidebarManu"
                      src={Permission}
                      alt="logo"
                      height="16px"
                      width="15px"
                    // style={{ marginRight: "14px" }}
                    />
                  </Col>

                  <Col span={20} style={{ paddingLeft: '10px' }}>
                    <Row >FilmItNow Access</Row>{" "}
                  </Col>
                </Row>
                {/* <Divider
                  style={{ margin: "14px 106px -10px -3px" }}
                  className="activeSidebarManu"
                /> */}
                <Row justify="start" style={{ width: "100%" }}>
                  <hr
                    style={{
                      width: "170px",
                      // width: "95%",
                      backgroundColor: "#63a921",
                      color: "#63a921",
                      marginBlockEnd: "0em",
                      marginBlockStart: "0em",
                      marginBottom: "0px",
                      marginInlineStart: "initial",
                      marginTop: "6px",
                    }}
                  />
                </Row>
              </>
            ) : (
              <Row align="middle" justify="start">

                <Col span={4}>
                  {" "}
                  <img
                    src={Permission}
                    alt="logo"
                    height="16px"
                    width="15px"
                  // style={{ marginRight: "14px" }}
                  />
                </Col>


                <Col span={20} style={{ paddingLeft: '10px' }}>
                  <Row >FilmItNow Access</Row>{" "}
                </Col>

              </Row>
            )}
          </Row>
        </NavLink>
      </Row>
      <Row style={{ padding: "6px 0px 14px 10px " }}>
        <NavLink
          to="/admin/ContentManagementWeb"
          activeClassName="active"
          className="text_white inactive"
          exact={true}
        >
          <Row align="middle">
            {currentPath == "/admin/ContentManagementWeb" ||
              currentPath == "/admin/EditingEarningWeb" ||
              currentPath == "/admin/EditingDisclaimerWeb" ? (
              <>
                <Row justify="start" >
                  {" "}
                  {/* <Col span={3} 
                  md={2} lg={1}
                  > */}{" "}
                  <Col span={4}>
                    {" "}
                    <img
                      src={ContentManagement}
                      alt="logo"
                      height="16px"
                      width="15px"
                      style={{ marginRight: "14px" }}
                    />
                  </Col>
                  {/* </Col> */}
                  {/* <Col xs={0} span={1} md={1} lg={1} />
                  <Col span={20} md={20} lg={21} style={{color:"#63a921"}}> */}
                  <Col span={20}>
                    <Row style={{ color: "#63a921" }}>Content Management</Row>{" "}
                  </Col>
                  {/* </Col> */}
                </Row>
                {/* <Divider
                  style={{ margin: "14px 25px -10px -3px" }}
                  className="activeSidebarManu"
                /> */}
                <Row justify="start" style={{ width: "100%" }}>
                  <hr
                    style={{
                      width: "170px",
                      // width: "95%",
                      backgroundColor: "#63a921",
                      color: "#63a921",
                      marginBlockEnd: "0em",
                      marginBlockStart: "0em",
                      marginBottom: "0px",
                      marginInlineStart: "initial",
                      marginTop: "6px",
                    }}
                  />
                </Row>
              </>
            ) : (
              <Row justify="start" style={{ width: "100%" }}>
                {/* <Col span={3}> */}
                <Col span={4}
                // xs={6} sm={4} md={4}
                >
                  {" "}
                  <img
                    className="activeSidebarManu2"
                    src={ContentManagement}
                    alt="logo"
                    height="16px"
                    width="15px"
                    style={{ marginRight: "14px" }}
                  />
                </Col>
                {/* </Col> */}
                {/* <Col xs={1} span={1} /> */}
                <Col
                  span={20}
                //  xs={18} sm={17} md={18}
                >
                  <Row> Content Management</Row>
                </Col>{" "}
              </Row>
            )}
          </Row>
        </NavLink>
      </Row>
      {/* </Row> */}
    </>
  );
};

export default SideBarWeb;
