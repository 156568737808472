import React, { useState, useEffect } from "react";
import { Col, Row, Spin, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import axios from "axios";
import { AdminUnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const { baseUrl } = urlRepacthaConfig();

const AdminDetailWeb = (props: any) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");

  const token: any = localStorage.getItem("access_token");
  const userId: any = localStorage.getItem("user_admin_id");

  const getData = async () => {
    await axios
      .get(`${baseUrl}bx_block_admin/admin_users/${userId}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        setDataLoading(false);
        console.log(res.data.admin_details, "resultresultresult");
        
        setUserName(
          res.data.admin_details.first_name +
            " " +
            res.data.admin_details.last_name
        );
        setEmail(res.data.admin_details.email);
        // setPassword(res.data.admin_details.first_name);
        setAddress(res.data.admin_details.address);
        setCity(res.data.admin_details.city);
        setState(res.data.admin_details.state);
        setCountry(res.data.admin_details.country);
        setZip(res.data.admin_details.zipcode);
       
      })
      .catch((err: any) => {
        if (err.response.status === 404 || 401) {
          return AdminUnAuthorized(props);
        }

        return err.respose;

       
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      {dataLoading ? (
        <Spin />
      ) : (
        <>
          <Row className="" align="middle" justify="center">
           
            <Col
              span={14}
           

              className="admin-requestlist-web"
            >
              <Row className="admin-detail-top-btn-sec">
                <button
                  className="top-btn1"
                  onClick={() =>
                    props.navigation.history.push(
                      "/admin/AdminUpdateProfileWeb"
                    )
                  }
                >
                  Update Profile
                </button>
                <button
                  className="top-btn2"
                  onClick={() =>
                    props.navigation.history.push("/admin/AdminSettingWeb")
                  }
                >
                  Change Settings
                </button>
              </Row>
              <p className="main2-head-txt-admin-home "> Admin Details</p>
           
              <Avatar
                className="admin-detail-profile-pic"
                shape="square"
                size={64}
                icon={<UserOutlined translate />}
              />
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">User Name</p>
                <p className="admin-detail-txt-data" data-testid="username">{userName}</p>
              </Col>
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">Email</p>
                <p className="admin-detail-txt-data" data-testid="email">{email}</p>
              </Col>
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">Password</p>
                <p className="admin-detail-txt-data admin-detail-pwd-data">
                  {password}
                </p>
              </Col>

              <p className="main2-head-txt-admin-home "> Address information</p>

              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">Address</p>
                <p className="admin-detail-txt-data" data-testid="address">{address}</p>
              </Col>
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">City</p>
                <p className="admin-detail-txt-data" data-testid="city">{city}</p>
              </Col>
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">State</p>
                <p className="admin-detail-txt-data" data-testid="state">{state}</p>
              </Col>
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">Country</p>
                <p className="admin-detail-txt-data" data-testid="country">{country}</p>
              </Col>
              <Col className="admin-detail-txt-rows">
                <p className="admin-detail-txt-head">Zip</p>
                <p className="admin-detail-txt-data" data-testid="zip">{zip}</p>
              </Col>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AdminDetailWeb;
