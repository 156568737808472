import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Typography } from "antd";
import axios from "axios";
import HeaderWeb from "../../../components/src/HeaderWeb";
import { openNotification } from "../../../components/src/Notification.web";
import { UnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const configJSON = require("./config.js")
const { format } = require('date-fns');
const {baseUrl} = urlRepacthaConfig();

type Document = {

  PictureTitle: string,
  eDocumentFile: string,
  ContractDate: string
}



const MyDocsWeb = ({ tabKey, props }: any) => {
  // const [data, setData] = useState<any>([]);
  const [data, setData] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState("")
  // const columns = [
  //   {
  //     title: "DOCUMENTS",
  //     dataIndex: "documents",
  //     key: "documents",
  //     // render: text => <a>{text}</a>,
  //     // render: documents => (
  //     //   <>
  //     //     {documents.map(document => {

  //     //       return (
  //     //         <Col  key={document}>
  //     //           {document.toUpperCase()}
  //     //         </Col>
  //     //       );
  //     //     })}
  //     //   </>
  //     // ),
  //   },
  //   {
  //     title: "LINKS",
  //     dataIndex: "link",
  //     key: "link",
  //   },
  //   {
  //     title: "SHARED ON",
  //     dataIndex: "sharedOn",
  //     key: "sharedOn",
  //   },
  // ];

  const formatDate = (dateString: string) => {
    const inputDate = new Date(dateString);

// Extract the date components
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getUTCDate()).padStart(2, "0");
    const year = inputDate.getUTCFullYear();

// Format the date as MM/DD/YYYY
    const formattedDate = month + "/" + day + "/" + year;
    return formattedDate;
  }




  const token: any = localStorage.getItem("user_token");

  const getData = async () => {
    setLoading(true)
    await axios
      .get(`${baseUrl}my/docs`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => {
        console.log(res.data, "my documents")
        setData(res.data.my_doc);
      })

      .catch((err: any) => {
        console.log(err?.response?.data, "errrr");
        err.response.status == 403 ? UnAuthorized(props) : ""

        // openNotification(err?.response?.data?.full_message, "error")
        setErrorText(err?.response?.data?.full_message)
      }).finally(() => {
        setLoading(false);

      })
  };
  useEffect(() => {

    {
      
        getData()
      
    }
  }, [tabKey]);

  return (
    <>
-    <HeaderWeb/>
    <Row justify="center" style={{marginTop: "40px"}} className="text_white p-5">

      {
        loading ? <Row align="middle" data-testid="spinner" justify="center" ><Col>
          <Spin size="large" />
        </Col></Row> : <Col
          xs={21}
          sm={20}
          md={20}
          lg={18}
          xl={15}
          xxl={11}
        >

          {!data ? <Row justify="center" data-testid="no-data-row" style={{
            backgroundColor: "#2D2D2D", padding: "18px",
            borderRadius: "20px",
          }}>No Doc Found!</Row> : (<>

            <Row className="font_size19 font_700 mb-2">{configJSON.myDocs}</Row>
            <Row style={{
              backgroundColor: "#2D2D2D", padding: "18px",
              borderRadius: "20px",
            }}>





              <Col span={24}>

                <Row justify="space-around" className="p-1 border_radius_2 table_header">
                  {/*@ts-ignore */}
                  <Col span={8} className="pl-2 font_700" testID="documentkey">{configJSON.document}</Col>
                  <Col span={8} className="font_700">{configJSON.title}</Col>
                  <Col span={8} className="pl-4 font_700">{configJSON.sharedOn}</Col>

                </Row>

                <Col style={{ gap: "2rem", marginTop: "1rem", display: "flex", flexDirection: "column" }}  >
                  {data.map((item: Document) => (
                    // @ts-ignore
                    <Row justify="space-around" testID="allthedocs">
                      <Col span={8} className="pl-4">{item.PictureTitle}</Col>
                      <Col span={8} >

                        <a href={item.eDocumentFile} target="_blank" style={{ color: "white" }} data-testid={`link-${item.PictureTitle}`}>
                          <Typography.Text ellipsis={true} style={{ color: "white" }}>
                            Distribution Agreement
                          </Typography.Text>
                        </a> </Col>
                      <Col span={8} className="pl-4">{formatDate(item.ContractDate)}</Col>

                    </Row>
                  ))}


                </Col>
              </Col>


            </Row>


          </>)}
        </Col>

      }

    </Row>
    </>
  );
};

export default MyDocsWeb;
