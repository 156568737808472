import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row, Spin, Form, Input } from "antd";

import SideBarWeb from "../../../components/src/SidebarWeb";
import axios from "axios";

import { openNotification } from "../../../components/src/Notification.web";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const {baseUrl} = urlRepacthaConfig()


const EditingContactSupportWeb = (props: any) => {
  const [saveModal, setSaveModal] = useState(false);
  const [form] = Form.useForm();
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const token: any = localStorage.getItem("access_token");

  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

  const handleOk1 = () => {
    setSaveModal(false);
  };

  console.log("form", form);
  const getData = async () => {
    await axios
      .get(`${baseUrl}admin/get/contact_data`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((res: any) => {
        setDataLoading(false);
        console.log(res.data, "ggggggg");

        form.setFieldsValue({
          phone: res.data.contact_content.phone,
          email: res.data.contact_content.email,
        });
      })
      .catch((err: any) => {
        console.log(err, "errrrr");
        setDataLoading(false)
      });
  };

  //     toolbar: {
  //       container: [
  //         ["bold", "italic", "underline"],
  //         [{ size: ["small", false, "large", "huge"] }],
  //         [{ align: "" }, { align: "right" }],
  //         [
  //           { list: "ordered" },
  //           {
  //             indent: "-1",
  //           },
  //           { indent: "+1" },
  //         ],
  //         ["link", "image"],
  //       ],
  //     },
  //     clipboard: { matchVisual: true },
  //   };

  useEffect(() => {
    getData();
  }, []);

  const updateContact = async () => {
    setLoading(true);

    const data = form.getFieldsValue();
    await axios
      .put(`${baseUrl}admin/update/contact_content`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((res: any) => {
        openNotification(res.data.full_message, "success");
        setLoading(false);
        getData();
        // setChangeData(res)
        setSaveModal(false);
        // full_message
      })
      .catch((error: any) => {
        openNotification("enter valid phone number", "error");
        console.log(error, "gggg");
        setLoading(false);
      });
  };

  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      <Row className="mt-5">
        <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} xl={20} md={17} sm={16} xs={14}>
          {" "}
          <Row
            // style={{ padding: "0px 12px" }}
            className="font_size23 font_700 mb-1"
          >
            {/* <Col xs={24} lg={24} xl={23}> */}
            Content Management
            {/* </Col> */}
            {/* <Col xl={1} /> */}
          </Row>
          <Row className="text_white font_size16 font_600" style={{ marginBottom: "8px" }}>
            Editing Contact Support
          </Row>
          <Row justify="center" className="text_white p-5">
            {/* <Col xl={1}/> */}
          <Col
              xs={24}
              sm={24}
              xl={23}
              style={{
                padding: "18px",
                // height:"200px",
                backgroundColor: "#222222",
                // minWidth: "600px",

                borderRadius: "20px",
              }}
            >
              {dataLoading ? (
                <Row className="mt-2" align="middle" justify="center" data-testid="spinner">
                  <Col>
                    {" "}
                    <Spin size="large" />
                  </Col>
                </Row>
              ) : (
                <Form form={form}>
                  <Row className="mb-4" justify="center">
                    <Col span={24}>
                      <Row justify="center">
                        <Form.Item
                          label="Phone"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please enter phone number",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter your phone number"
                            className="admin-update-profile-form"
                            maxLength={20}
                            min={20} 
                            max={20}
                          />
                        </Form.Item>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="center">
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Email!",
                            },
                            {
                              pattern: emailRegex,
                              message: "Enter Valid email!",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            placeholder="Enter your email"
                            className="admin-update-profile-form"
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}

              <Row className="mt-6" align="middle" justify="end">
                <Col>
                  <Button
                    // onClick={handleCancel}
                    onClick={() =>
                      props.navigation.history.push(
                        "/admin/ContentManagementWeb"
                      )
                    }
                    className="ant-modal-btn-secondary  font_size16 font_700 width-120 height-40"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    loading={loading}
                    // onClick={()=>setSaveModal(true)}
                    onClick={() =>
                      !form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length &&
                      setSaveModal(true)
                    }
                    className="ant-modal-btn-primary font_700 ml-1 font_size16   width-120 height-40"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Modal
                className="delete_modal"
                // title="Basic Modal"

                closable={false}
                visible={saveModal}
                centered
              >
                <Row
                  align="middle"
                  justify="center"
                  className="font_size26 mb-1"
                  data-testid="save_changes"
                >
                  Save Changes
                </Row>
                <Row
                  align="middle"
                  justify="center"
                  style={{ textAlignLast: "center" }}
                  className="font_size16 mb-1"
                >
                  Do you want to save the changes that <br /> you've made in the
                  content?
                </Row>
                <Row align="middle" justify="center">
                  <Col>
                    <Button
                      onClick={handleOk1}
                      className="ant-modal-btn-secondary font_size16 font_700 mt-1 ml-1 width-120 height-40"
                      style={{ width: "130px" }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      loading={loading}
                      // onClick={handleCancel1}
                      onClick={updateContact}
                      className="ant-modal-btn-primary mt-1 ml-1 font_size16 font_700  height-40"
                      style={{ width: "130px" }}
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Col>
            <Col xl={1} />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditingContactSupportWeb;
