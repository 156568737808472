import React, { useState ,useEffect} from "react";
import { Modal, Radio, Row, Col, Button,Spin } from "antd";
import "../assets/login.css";
import { openNotification } from "../../../components/src/Notification.web";
import axios from "axios";
import { UnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "./urlConfig";
const baseUrl = {urlRepacthaConfig};
const EarningDetailsWeb = (props: any) => {
  const handleOk = () => {
    console.log("ss");
  };

  const handleCancel = () => {
    console.log("ss");
  }; 
  const handleSubmit = async () => {
    props.navigation.history.push("/ContentReportsWeb");
  };
  const token: any = localStorage.getItem("user_token");
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getEarningDetails()
  }, [])
  const [earning, setEarning] = useState('')
  const getEarningDetails = async () => {
    await axios
      .get(`${baseUrl}earning/content`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => {  
         setEarning(res.data.earning_content[0].detail); 
         
      })
      .catch((err: any) => { 
        err.response.status == 404||401 ? UnAuthorized(props) : ""
        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);
      })
  };
  return (
    <div>
      <Modal
        width="50%"
        centered
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >{loading?<Row align="middle" justify="center" data-testid="spinner"><Col>
      <Spin size="large" />
      </Col></Row> :<>
        <Row align="middle" justify="center" data-testid="content">
          <Col span={24}>
            <h1 className="text_white" style={{ textAlign: "center" }}>
              Earning Details
            </h1>
          </Col>
        </Row>
        <Row>
          <Col> 
            <p dangerouslySetInnerHTML={{ __html:earning }} className="text_white" style={{ textAlign: "left" }}>
               {/* {disclaimer} */}
            </p>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <p className="text_white" style={{ textAlign: "center" }}>
              This estimate is further reduced by expenses/deductions, per the
              License Agreement, and the net revenue/receipts are allocated
              between the Licensor and GA (Licensee). These estimates are
              adjusted at the end of each quarter period and the earnings
              provided to the Licensor no later than 30 days after quarter end.
            </p>
          </Col>
        </Row> */}

        <Row align="middle" justify="space-around" className="mt-1">
          <Col>
            <Button
              type="primary"
              style={{ width: "100px" }}
              onClick={()=> props.navigation.history.push("/ContentReportsWeb")}
            >
              Ok
            </Button>
          </Col>
        </Row></>}
      </Modal>
    </div>
  );
};

export default EarningDetailsWeb;
