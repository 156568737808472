import React, { useEffect, useState } from "react";
import { Form, Input, Select, Row, Col, Button, Spin } from "antd";
import "../assets/account.css";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import _ from "lodash";
import { openNotification } from "../../../components/src/Notification.web";
import { UnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const {baseUrl, recaptcha} = urlRepacthaConfig()

const { TextArea } = Input;
const { Option } = Select;
const UserProfileWeb = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();

  const token: any = localStorage.getItem("user_token");
  const userId: any = localStorage.getItem("user_id");

  const onFinish = async (values: any) => {
    setLoading(true);
    await axios
      .post(`${baseUrl}update/profile`, values, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => {
        setLoading(false);
        // form.resetFields();
        getProfile();
        openNotification(res.data.full_message, "success");
      })
      .catch((err: any) => {
        setLoading(false);
        openNotification(err?.response?.data?.full_message, "error");
      });
  };
  const [country, setCountry] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}user/details/${userId}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => {
        const data = {
          ...res.data.account,
          zip_code: res.data.account.zipcode,
        };
        setStates(
          _.orderBy(
            _.filter(
              State.getAllStates(),
              (item: any) => item.countryCode == res.data.account.country
            ),
            ["name"],
            ["asc"]
          )
        );
        setCity(
          _.orderBy(
            _.filter(
              City.getAllCities(),
              (item: any) =>
                item.stateCode == res.data.account.state &&
                item.countryCode == res.data.account.country
            ),
            ["name"],
            ["asc"]
          )
        );
        setSelectedCountry(res.data.account.country);
        setUserInfo(data);
        // openNotification(res.data.full_message, "success")
      })
      .catch((err: any) => {
        if (err.response.status == 404 || 403) {
          UnAuthorized(props);
        }

        openNotification(err?.response?.data?.full_message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setCountry(Country.getAllCountries());
    // setStates(State.getAllStates())
    // setCity(City.getAllCities())
  }, []);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState<any>("");
  

  useEffect(() => {
    setStates(
      _.orderBy(
        _.filter(
          State.getAllStates(),
          (item: any) => item.countryCode == selectedCountry
        ),
        ["name"],
        ["asc"]
      )
    );
    // console.log(selectedCity,selectedState);
    // selectedState==""?"":
    // form.setFieldsValue({
    //   state:'',
    //   city:''
    // })
  }, [selectedCountry]);

  useEffect(() => {
    setCity(
      _.orderBy(
        _.filter(
          City.getAllCities(),
          (item: any) =>
            item.stateCode == selectedState &&
            item.countryCode == selectedCountry
        ),
        ["name"],
        ["asc"]
      )
    );
  }, [selectedState]);

  return (
    <Row justify="center">
      <Col span={16}>
        <h2 className="text_white font_700">User Information</h2>
        {loading ? (
          <Row
            className="mt-2"
            data-testid="loading"
            justify="center"
            align="middle"
          >
            <Col>
              {" "}
              <Spin size="large" />
            </Col>
          </Row>
        ) : (
          <Form
            className="profile_form"
            layout="vertical"
            autoComplete="off"
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={userInfo}
            scrollToFirstError
          >
            <Row gutter={[30, 0]}>
              <Col span={12}>
                <Form.Item
                  name="first_name"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input First Name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your first name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="last_name"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Last Name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your Last name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="company_name" label="Company Name">
                  <Input placeholder="Enter your company name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <h2 className="text_white font_700">Address Information</h2>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="address"
                  data-testid="address"
                  label="Address"
                >
                  <TextArea rows={4} placeholder="Enter your Address" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                >
                  <Select
                    data-testid="country"
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="-Select Country-"
                    onChange={(e) => {
                      setSelectedCountry(e);
                      form.setFieldsValue({
                        state: "",
                        city: "",
                      });
                    }}
                  >
                    {country.map((item: any) => (
                      <Option
                        key={item.isoCode}
                        data-testid="countryWrapper"
                        value={item.isoCode}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                >
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="-Select State-"
                    onChange={(e) => {
                      setSelectedState(e);
                      form.setFieldsValue({ city: "" });
                    }}
                  >
                    {states.map((item: any) => (
                      <Option key={item.isoCode} value={item.isoCode}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                >
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="-Select City-"
                    // onChange={(e) => setSelectedCity(e)}
                  >
                    {city.map((item: any) => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="zip_code"
                  label="Zip"
                >
                  <Input placeholder="Enter the zip code" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="no_border">
              <Button
                style={{ width: "111px" }}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default UserProfileWeb;
