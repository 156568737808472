import { Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import HeaderWeb from "../../../components/src/HeaderWeb";
import "../assets/account.css";
// import BalanceWeb from "./BalanceWeb";
import MyDocsWeb from "./MyDocsWeb";
import UserProfileWeb from "./UserProfileWeb";
const { TabPane } = Tabs;

const AccountWeb = (props: any) => {
  const [tabKey, setTabKey] = useState("");
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };
  const callback = (key: any) => {
    console.log(key);
    setTabKey(key);
  };
  console.log(tabKey, "tabKeytabKey");

  return (
    <>
      <HeaderWeb />
      <Row justify="center" className="pt-1">
        <Col span={24}>
              <UserProfileWeb props={props} />
        </Col>
      </Row>
    </>
  );
};

export default AccountWeb;
