import React, { useEffect, useState } from "react";
import {
  MailOutlined,
  LockOutlined,
  SyncOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/forgot.css";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Divider,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import { AdminUnAuthorized } from "../../../components/src/helper";
const logo = require("../../../components/src/image_Greenapple.png");
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const {baseUrl, recaptcha}  = urlRepacthaConfig()


const AdminForgotPasswordWeb = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // const onFinish = (values: any) => {
  //   console.log("Received values of form: ", values);
  // };
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_admin_id");
  }, []);
  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      ...values,
      "g-recaptcha-response": recaptchaRes,
    };
    await axios
      .post(`${baseUrl}admin/forgot/password`, data)
      .then((res) => {
        openNotification(res.data.full_message, "success");
        props.navigation.history.push("/admin/login");
        form.resetFields();
      })
      .catch((err) => {
        openNotification(err?.response?.data?.full_message, "error")
 
   
      })
      .finally(() => {
        setLoading(false);
        setRecaptchaRes("");
        resetCaptcha();
      });
  };
  const [recaptchaRes, setRecaptchaRes] = useState("");
  let captcha: any;

  const onChange = (value: any) => {
    setRecaptchaRes(value);
  };

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return (captcha = ref);
    }
  };

  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };
  return (
    <>
      <Row
        className="login"
        style={{
          backgroundColor: "#212121",
          marginTop: "70px",
          marginBottom: "0px",
        }}
      />
      <Row justify="center">
        <img
          src={logo}
          alt="logo"
          height="50px"
          width="230px"
          // onClick={() => props.navigation.history.push("/AccountWeb")}
        />
      </Row>
      <Row align="middle" justify="center">
        {/* <Divider style={{ backgroundColor: "#212121", marginTop: "80px" }} /> */}
        <Col sm={18} md={13} lg={10} xl={9} xxl={9} xs={20}>
          <Row
            align="middle"
            justify="center"
            className="mt-1"
            style={{ fontSize: "30px", fontWeight: 600, marginBottom: "20px" }}
          >
            Forgot Password
          </Row>
          <Form
            className="login_form"
            style={{ marginTop: "70px" }}
            //   {...formItemLayout}
            autoComplete="off"
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
              residence: ["zhejiang", "hangzhou", "xihu"],
              prefix: "86",
            }}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                data-testid="email"
                type="email"
                prefix={
                  <MailOutlined
                    translate="true"
                    style={{
                      color: "#676666",
                      fontSize: "19px",
                      marginRight: "7px",
                    }}
                  />
                }
                placeholder="Email Address"
                //   style={{ backgroundColor: "black", color: "white" }}
                bordered={false}
              />
            </Form.Item>

            <Form.Item>
              {/* @ts-ignore */}
              <ReCAPTCHA
                // sitekey={recaptcha} onChange={onChange}

                ref={(r: any) => setCaptchaRef(r)}
                sitekey={recaptcha}
                onChange={onChange}
                theme="dark"
              />
            </Form.Item>
            <Form.Item>
              <Button
                //  onClick={() =>
                //   props.navigation.history.push("ResetPasswordWeb")
                // }

                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#63A921",
                  border: "0px",
                  color: "black",
                  fontSize: "21px",
                  fontWeight: 600,
                  padding: "5px",
                  height: "50px",
                  width: "230px",
                }}
              >
                Send Password
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default AdminForgotPasswordWeb;
