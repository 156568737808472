import React, { useEffect, useState } from "react";
import {
  MailOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  SyncOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/login.css";
import Cookies from "js-cookie";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Divider,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";
import axios from 'axios'
import { openNotification } from "../../../components/src/Notification.web";
import urlRepacthaConfig from "./urlConfig";
const {baseUrl,recaptcha} = urlRepacthaConfig()

const logo = require("../../../components/src/image_Greenapple.png");
// const logo = require("./image_Greenapple.png");

const EmailAccountAdminLoginWeb = (props: any) => {
  // const checkAuth = () => {
  //   window.localStorage.getItem("access_token") ? props.navigation.history.push("/AccountWeb") : window.localStorage.clear()
  // }
  // useEffect(() => {
  //   checkAuth()
  // }, [])
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [remeber, setRemeber] = useState(false)
  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      ...values, "g-recaptcha-response": recaptchaRes
    }
    await axios
      .post(`${baseUrl}authenticate`, data)
      .then((res) => {
        localStorage.setItem('access_token', res.data.token);
        localStorage.setItem('user_admin_id', res.data.id);
        form.resetFields();
        props.navigation.history.push("/admin/Analytics3Web")
        openNotification(res.data.full_message, "success")
        if (remeber) {
          Cookies.set("access_token", res && res.data && res.data.token);
        }
      })
      .catch((err) => {
        // @ts-ignore
        const error = err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].full_message;
        openNotification(error, "error");
      }).finally(() => {
        setLoading(false)
        setRecaptchaRes('')
        resetCaptcha()
      })
  }; 
  const [recaptchaRes, setRecaptchaRes] = useState('')
  let captcha: any;

  const onChange = (value: any) => {
    setRecaptchaRes(value);
  }

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
  };

  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  }

  return (
    <>
      <Row className="login" style={{marginTop: "70px", marginBottom: "0px"}} />
      <Row justify="center">
        <img
          src={logo}
          alt="logo"
          height="50px"
          width="230px"
        // onClick={() => props.navigation.history.push("/AccountWeb")}
        />
      </Row>
      <Row align="middle" justify="center">
        <Col sm={15} md={13} lg={10} xl={9} xxl={9} xs={20}>

          <Row
            className="mt-1"
            justify="center"
            style={{ fontSize: "26px", fontWeight: 600 }}
          >

            Let's Sign You In
          </Row>
          <Form
            className="login_form"
            style={{ marginTop: "70px" }}
            //   {...formItemLayout}
            autoComplete="off"
            form={form}
            name="register"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                data-testid="email"
                type="email"
                prefix={
                  <MailOutlined
                    translate="true"
                    style={{
                      color: "#676666",
                      fontSize: "19px",
                      marginRight: "7px",
                    }}
                  />
                }
                placeholder="Email"
                //   style={{ backgroundColor: "black", color: "white" }}
                bordered={false}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
              data-testid="password"
                className="input_login"
                prefix={
                  <LockOutlined
                    translate="true"
                    style={{
                      color: "#676666",
                      fontSize: "21px",
                      marginRight: "7px",
                    }}
                  />
                }
                type="password"
                placeholder="Password"
                bordered={false}
                iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}
              />
            </Form.Item>
            {/* <Form.Item> */}
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Row justify="space-between">
                {" "}
                <Col>
                  {" "}
                  <Checkbox onChange={(e: any) => setRemeber(e.target.checked)}>
                    <p className="text_white"> Remember me</p>
                  </Checkbox>
                </Col>
                <Col className="login-form-forgot">
                  <Button

                    className="text_white"
                    type="link"
                    onClick={() =>
                      props.navigation.history.push("ForgotPasswordWeb")
                    }
                  >
                    Forgot password
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="recaptcha_response">
              <Row style={{ marginTop: "30px" }}>
                <ReCAPTCHA ref={(r:any) => setCaptchaRef(r)}
              sitekey={recaptcha}
              onChange={onChange}
              theme="dark" />
              </Row>
            </Form.Item>
            {/* </Form.Item> */}
            <Form.Item className="no_border">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className="mt-2"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#63A921",
                  border: "0px",
                  color: "black",
                  fontSize: "21px",
                  fontWeight: 600,
                  padding: "5px",
                  height: "50px",
                  width: "140px",
                }}
              >
                Sign in
              </Button>
            </Form.Item>

          </Form>
        </Col>
      </Row>
    </>
  );
};

export default EmailAccountAdminLoginWeb;
