import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row, Spin } from "antd";

import SideBarWeb from "../../../components/src/SidebarWeb";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";

import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const { baseUrl } = urlRepacthaConfig()


const EditingEarningWeb = (props: any) => {
  const [earnings, setEarnings] = useState();
  const [saveModal, setSaveModal] = useState(false);

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const token: any = localStorage.getItem("access_token");

  const getData = async () => {
    await axios
      .get(`${baseUrl}admin/earning/content`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        setDataLoading(false);

        setEarnings(res.data.earning_content.detail);
      })
      .catch((err: any) => {
        console.log(err, "errrrr");
      });
  };
  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: "" }, { align: "right" }],
        [
          { list: "ordered" },
          {
            indent: "-1",
          },
          { indent: "+1" },
        ],
        ["link", "image"],
      ],
    },
    clipboard: { matchVisual: true },
  };
  //   console.log(earnings, "vvvvv");
  useEffect(() => {
    getData();
  }, []);
  const updateEarning = async () => {
    setLoading(true);
    const data = {
      earning_content: earnings,
    };
    await axios
      .put(`${baseUrl}admin/update/earning`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        openNotification(res.data.full_message, "success");
        setSaveModal(false);
        setLoading(false);
        getData();
      })
      .catch((error: any) => {
        openNotification("something went wrong", "error");
        console.log(error, "gggg");
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setSaveModal(false);
  };

  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      <Row className="mt-5">
        <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} xl={20} md={17} sm={16} xs={14}>
          {" "}
          <Row
            // style={{ padding: "0px 12px" }}
            className="font_size23 font_700 mb-1"
          >
            {/* <Col xs={24} lg={24} xl={23}> */}
            Content Management
            {/* </Col> */}
            {/* <Col xl={1} /> */}
          </Row>
          <Row className="text_white font_size16 font_600" style={{ marginBottom: "8px" }}>
            Editing Earnings
          </Row>
          <Row
          justify="center"
            // style={{ padding: "0px 12px" }}
            className="text_white p-5"
          >
            {/* <Col xl={1}/> */}
            <Col
              xs={24}
              sm={24}
              xl={23}
              style={{
                padding: "18px",
                // height:"200px",
                backgroundColor: "#222222",
                // minWidth: "600px",

                borderRadius: "20px",
              }}
            >
              {dataLoading ? (
                <Row className="mt-2" align="middle" justify="center" data-testid="spinner">
                  <Col>
                    {" "}
                    <Spin size="large" />
                  </Col>
                </Row>
              ) : (
                <Row className="mb-4">
                  {/* @ts-ignore */}
                  <ReactQuill
                    className="earning_reactQuill"
                    style={{ height: "200px", width: "100%" }}
                    theme="snow"
                    value={earnings ? earnings : ""}
                    onChange={(value: any) => setEarnings(value)}
                    modules={modules}
                  />
                </Row>
              )}

              <Row className="mt-6" align="middle" justify="end">
                <Col>
                  <Button
                    // onClick={handleOk}
                    onClick={() =>
                      props.navigation.history.push(
                        "/admin/ContentManagementWeb"
                      )
                    }
                    className="ant-modal-btn-secondary font_size16 font_700 width-120 height-40"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    // loading={loading}
                    onClick={() => setSaveModal(true)}
                    className="ant-modal-btn-primary font_700 font_size16 ml-1 width-120 height-40"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Modal
                className="delete_modal"
                // title="Basic Modal"
                data-testid="saveModal"
                closable={false}
                visible={saveModal}
                // onOk={handleOk1}
                // onCancel={handleCancel1}
                centered
              >
                <Row
                  align="middle"
                  justify="center"
                  className="font_size26 mb-1"
                >
                  Save Changes
                </Row>
                <Row
                  align="middle"
                  justify="center"
                  style={{ textAlignLast: "center" }}
                  className="font_size16 mb-1"
                >
                  Do you want to save the changes that <br /> you've made in the
                  content?
                </Row>
                <Row align="middle" justify="center">
                  <Col>
                    <Button
                      onClick={handleCancel}
                      className="ant-modal-btn-secondary mt-1 ml-1 width-120 height-40"
                      style={{ width: "130px" }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      loading={loading}
                      onClick={updateEarning}
                      className="ant-modal-btn-primary mt-1 ml-1  width-120 height-40"
                      style={{ width: "130px" }}
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Col>
            <Col xl={1} />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditingEarningWeb;
