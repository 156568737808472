import React from "react";

import {
    Col,
    Row,
    Menu,
} from "antd";
import { UserOutlined, ContactsOutlined } from "@ant-design/icons";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";

const { SubMenu } = Menu;

const AdminUpdateSetting = (props: any) => {

    return (
        <>
            <AdminHeaderWeb />

            <Row align="middle" justify="center">

                <Col xs={0} sm={3} lg={0} md={0} />
                <Col
                    xs={20}
                    sm={15}
                    md={14}
                    lg={14}
                >
                    <Row>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Row className="admin-detail-top-lft-btn-sec">
                                <button
                                    className="top-btn2"
                                    style={{cursor: "pointer"}}
                                    onClick={() =>
                                        props.navigation.history.push("/admin/Analytics3Web")
                                    }
                                >
                                    Back to admin console
                                </button>
                            </Row>
                        </Col>

                    </Row>
                </Col>
                <Col xs={0} sm={2} md={0} lg={0} />
                <Col
                    xs={20}
                    sm={14}
                    lg={14}
                    className="admin-requestlist-web"
                >
                    <p className="main2-head-txt-admin-home "> Admin Settings</p>
                    <Row justify="start">
                        <Col span={24} className="admin-setting-web-chng-pass">

                            <Menu
                                className="changePassDrop text_white  border_radius_2 table_body"
                                mode="inline"
                            >
                                <SubMenu
                                    key="sub1"
                                    title={
                                        <Row
                                            align="middle"
                                            onClick={() => props.navigation.history.push("/admin/AdminUpdateSettingWeb")}
                                        >
                                            <UserOutlined
                                                translate="true"
                                                className="LogoutOutlined"
                                            />{" "}
                                            <Row className="text_white font_size16 font_500">
                                                Change Logo
                                            </Row>
                                        </Row>
                                    }
                                />
                            </Menu>


                        </Col>
                        <Col span={24} className="admin-setting-web-chng-pass">


                            <Menu
                                className="changePassDrop text_white  border_radius_2 table_body"
                                mode="inline"
                            >
                                <SubMenu
                                    key="sub1"
                                    title={
                                        <Row
                                            align="middle"
                                            onClick={() => props.navigation.history.push("/admin/EditingContactSupportWeb")}
                                        >
                                            <ContactsOutlined
                                                translate="true"
                                                className="LogoutOutlined"
                                            />{" "}
                                            <Row className="text_white font_size16 font_500">
                                                Contact Support
                                            </Row>
                                        </Row>
                                    }
                                />
                            </Menu>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
};

export default AdminUpdateSetting;
