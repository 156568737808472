import React, { useState } from "react";
import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import {
  Col,
  Row,
  Select,
  Modal,
  Button,
  Form,
  Input,
  Collapse,
  Menu,
} from "antd";
import { UserOutlined, LogoutOutlined ,EyeTwoTone ,EyeInvisibleOutlined } from "@ant-design/icons";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import { AdminUnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const { baseUrl } = urlRepacthaConfig();

type RequiredMark = boolean | "optional";

const { Option } = Select;

const { Panel } = Collapse;

const { SubMenu } = Menu;

const AdminSettingWeb = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState();



  const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
    `;

  const submitPasswordForm = (values: any) => {
    delete values.confirm_password;
    setFormData(values);
    // const data = {
    //     ...values,
    // }
  };

  const submitFormData = async (values: any) => {
    console.log(values, "--values--");
    await axios
      .post(
        `${baseUrl}bx_block_admin/admin_users/${userId}/change_password`,
        values,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: token,
          },
        }
      )
      .then((res) => {
        // form.resetFields();
        // setEditmodal(false);
        // props.navigation.history.push("/EarningDetailsWeb")
        setLoading(false);
        setVisible(false);

        openNotification(res.data.full_message, "success");
      })
      .catch((err: any) => {
        // openNotification(err?.response?.data?.errors[0]?.full_message, "error")
        openNotification(err?.response?.data?.full_message, "error");
        setLoading(false);
        setVisible(false);
      })
      .finally(() => {
        // setStatus('')
      });
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
    submitFormData(formData);
    // setTimeout(() => {
    //     setLoading(false)
    //     setVisible(false)
    // }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const token: any = localStorage.getItem("access_token");
  const userId: any = localStorage.getItem("user_admin_id");

  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      <Row align="middle" justify="center">
        {/* <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props}/>
          </Col>{" "} */}
        <Col xs={0} sm={3} lg={0} md={0} />
        <Col
          // span={17}
          xs={20}
          sm={15}
          md={14}
          lg={14}
        >
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Row className="admin-detail-top-lft-btn-sec">
                <button
                  className="top-btn2"
                  style={{cursor: "pointer"}}
                  onClick={() =>
                    props.navigation.history.push("/admin/Analytics3Web")
                  }
                >
                  Back to admin console
                </button>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Row
                justify="end"
                className="mb-1 admin-detail-top-btn-sec-setting"
              >
                <button
                  className="top-btn2 ml-0 mt-1"
                  style={{cursor: "pointer"}}
                  onClick={() =>
                    props.navigation.history.push(
                      "/admin/AdminUpdateProfileWeb"
                    )
                  }
                >
                  Update Profile
                </button>
                <button
                  className="top-btn1 ml-2 mt-1"
                  style={{cursor: "pointer"}}
                  onClick={() =>
                    props.navigation.history.push("/admin/AdminSettingWeb")
                  }
                >
                  Change Settings
                </button>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={2} md={0} lg={0} />
        <Col
          //   lg={18}
          //   xl={20}
          //   md={17}
          //   sm={16}
          xs={20}
          sm={14}
          lg={14}
          className="admin-requestlist-web"
        >
          <p className="main2-head-txt-admin-home "> Admin Settings</p>
          <Row justify="start">
            <Col span={24} className="admin-setting-web-chng-pass">
              <Collapse
                bordered={false}
                // defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <UserOutlined translate />}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header="Change Password"
                  key="1"
                  className="site-collapse-custom-panel"
                  data-testid="changePassword"
                >
                  <Form
                    form={form}
                    layout="vertical"
                    data-testid="changePasswordForm"
                    // initialValues={{ requiredMarkValue: requiredMark }}
                    // requiredMark={requiredMark}
                    onFinish={submitPasswordForm}
                  >
                    <Row data-testid="oldPassword">
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          label="Enter Current Password"
                          name="old_password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Current password",
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="Enter Current Password"
                            className="admin-update-profile-form-password"
                            iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}
                            
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          data-testid="newPassword"
                          label="Enter New Password"
                          name="new_password"
                          // hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please enter New password",
                            },
                            {
                              min: 8,
                              message: "Please enter at least 8 charecters",
                            },
                            {
                              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%#])[A-Za-z\d@$%#]{8,}$/,
                              message:
                                "Password must contain one uppercase, one lowercase, one number and one special charecter (@,$,%,#).",
                            },
                          ]}
                        // validateTrigger="onBlur"
                        >
                          <Input.Password
                            placeholder="Enter New Password"
                            className="admin-update-profile-form-password"
                            iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          label="Re-enter new password"
                          className="form-item"
                          name="confirm_password"
                          // hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            {},

                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("new_password") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "The two passwords that you entered do not match!"
                                );
                              },
                            }),
                          ]}
                          dependencies={["new_password"]}
                        // validateTrigger="onBlur"
                        >
                          <Input.Password
                            placeholder="Re-enter New password"
                            name="confirm_password"
                            size="large"
                            className="admin-update-profile-form-password"
                            iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item>
                      <Button
                        type="primary"
                        className="admin-update-profile-form-btn"
                        htmlType="submit"
                        onClick={() => showModal()}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24} className="admin-setting-web-chng-pass">
              {/* <Collapse
                            bordered={false}
                            // defaultActiveKey={['1']}
                            expandIcon={({ isActive }) => <LoginOutlined translate />}
                            className="site-collapse-custom-collapse"
                        >
                            <Panel header={<Row className="text_white font_size16 font_500" onClick={() => (localStorage.clear(), props.navigation.history.push("/admin/login"))}>LogOut</Row>} key="1" className="site-collapse-custom-panel" />
                               
                        </Collapse> */}

              <Menu
                className="changePassDrop text_white  border_radius_2 table_body"
                mode="inline"
              >
                <SubMenu
                  key="sub1"
                  title={
                    <Row
                      align="middle"
                      onClick={() => AdminUnAuthorized(props)}
                    >
                      <LogoutOutlined
                        translate="true"
                        className="LogoutOutlined"
                      />{" "}
                      <Row className="text_white font_size16 font_500">
                        Logout
                      </Row>
                    </Row>
                  }
                />
              </Menu>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        className="admin-settings-pwd-update-modal"
        visible={visible}
        //   title="Title"
        data-testid="updateModal"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row
            className="admin-settings-pwd-update-modal-footer-sec"
            justify="center"
          >
            <Button
              key="back"
              onClick={handleCancel}
              className="admin-settings-pwd-update-modal-footer-sec-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
              className="admin-settings-pwd-update-modal-footer-sec-save-btn"
            >
              Yes
            </Button>
          </Row>,
        ]}
      >
        <p className="admin-settings-pwd-update-modal-head">Save Changes</p>
        <p className="admin-settings-pwd-update-modal-txt">
          Do you want to save the current changes?
        </p>
      </Modal>
    </>
  );
};

export default AdminSettingWeb;
