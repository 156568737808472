import React, { useEffect } from "react";

// import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";

// @ts-ignore
import HeaderWeb from "../../../components/src/HeaderWeb";
import { Row, Spin } from "antd";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const {baseUrl} = urlRepacthaConfig()
// import { LoadingWeb } from "../../../components/src/LoadingWeb";
const VerifyEmailWeb = (props: any) => {
  // const history = useHistory();
  // const location = useLocation();
  useEffect(() => {
    verifyAcc();
  }, []);
  const verifyAcc = async () => {
    const vToken: any = location.pathname.split("/").pop();
    await axios
      .patch(`${baseUrl}verify/email/${vToken}`)
      .then((res: any) => {
        console.log(res.data, "ressss");
        openNotification(res.data.full_message, "success");
        props.navigation.history.push("/")
      })
      .catch((err: any) => {  
        openNotification(err.response.data.errors[0].account, "error");
        props.navigation.history.push("/")
      });
  };
  // const verifyAcc = async () => {
  //   const vToken = location.pathname.split("/").pop();
  //   const result = await axios.get(
  //       `${baseUrl}verify......`
  //   );
  //   if (result) {
  //     openNotification(result.data.message, "success");
  //     // history.push({
  //     //   pathname: `/NewPasswordWeb/${vToken}`,
  //     // });
  //   } else {
  //       console.log(err,"");

  //     // history.push("/EmailAccountLoginWeb");
  //   }
  // };

  return (
    <>
      <HeaderWeb />
      <Spin size="large" />
    </>
  );
};

export default VerifyEmailWeb;
