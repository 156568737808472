import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row, Spin } from "antd";

import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { openNotification } from "../../../components/src/Notification.web";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
const EditIcon = require("./Edit@3x.png");
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const {baseUrl} = urlRepacthaConfig()

const EditingDisclaimerWeb = (props: any) => {
  const [saveModal, setSaveModal] = useState(false);
  const [disclaimer, setDisclaimer] = useState();
  const [changeData, setChangeData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const token: any = localStorage.getItem("access_token");
  // const handleCancel = () => {
  //   // setAddNewUserModal(false);

  // };

  const handleOk1 = () => {
    setSaveModal(false);
  };
  const handleCancel1 = () => {
    setSaveModal(false);
  };

  const getData = async () => {
    await axios
      .get(`${baseUrl}admin/disclaimer/content`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        setDataLoading(false);
        console.log(res, "ggggggg");

        console.log(res.data.disclaimer_content.detail, "resultresultresult");
        setDisclaimer(res.data.disclaimer_content.detail);
      })
      .catch((err: any) => {
        console.log(err, "errrrr");
      });
  };
  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: "" }, { align: "right" }],
        [
          { list: "ordered" },
          {
            indent: "-1",
          },
          { indent: "+1" },
        ],
        ["link", "image"],
      ],
    },
    clipboard: { matchVisual: true },
  };
  console.log(disclaimer, "vvvvv");
  useEffect(() => {
    getData();
  }, []);

  const updateDisclaimer = async () => {
    setLoading(true);

    const data = {
      disclaimer_content: disclaimer,
    };
    await axios
      .put(`${baseUrl}admin/update/disclaimer`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        openNotification(res.data.full_message, "success");
        setLoading(false);
        getData();
        // setChangeData(res)
        setSaveModal(false);
        // full_message
      })
      .catch((error: any) => {
        console.log(error, "gggg");
      });
  };
  return (
    <>
      <AdminHeaderWeb />
      {/* <Row style={{height:"65px"}}/> */}
      <Row className="mt-5">
        <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} xl={20} md={17} sm={16} xs={14}>
          {" "}
          <Row
            // style={{ padding: "0px 12px" }}
            className="font_size23 font_700 mb-1"
          >
            {/* <Col xs={24} lg={24} xl={23}> */}
            Content Management
            {/* </Col> */}
            {/* <Col xl={1} /> */}
          </Row>
          
          <Row className="text_white font_size16 font_600" style={{ marginBottom: "8px" }}>
            Editing Disclaimer
          </Row>
          <Row justify="center" className="text_white p-5">
            {/* <Col xl={1}/> */}
            <Col
              xs={24}
              sm={24}
              xl={23}
              style={{
                padding: "18px",
                // height:"200px",
                backgroundColor: "#222222",
                // minWidth: "600px",

                borderRadius: "20px",
              }}
            >
              {dataLoading ? (
                <Row
                  className="mt-2"
                  align="middle"
                  justify="center"
                  data-testid="spinner"
                >
                  <Col>
                    {" "}
                    <Spin size="large" />
                  </Col>
                </Row>
              ) : (
                <Row className="mb-4 " data-testid="Editor">
                  {/* @ts-ignore */}
                  <ReactQuill
                    className="earning_reactQuill"
                    style={{ height: "200px", width: "100%" }}
                    theme="snow"
                    value={disclaimer ? disclaimer : ""}
                    onChange={(value: any) => setDisclaimer(value)}
                    modules={modules}
                  />
                </Row>
              )}

              {/* <Row
                className="p-1 font_500 text_white"
                style={{
                  backgroundColor: "#2D2D2D",
                  borderRadius: "10px",
                }}
              >
                  <Col span={24}> {loading ? <Row className="mt-2" align="middle" justify="center" ><Col > <Spin size="large" /></Col></Row>:  `${disclaimer}.`}</Col>
               
              
              </Row> */}
              <Row className="mt-6" align="middle" justify="end">
                <Col>
                  <Button
                    // onClick={handleCancel}
                    onClick={() =>
                      props.navigation.history.push(
                        "/admin/ContentManagementWeb"
                      )
                    }
                    className="ant-modal-btn-secondary  font_size16 font_700 width-120 height-40"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    // loading={loading}
                    // onClick={()=>setSaveModal(true)}
                    onClick={() => setSaveModal(true)}
                    className="ant-modal-btn-primary font_700 ml-1 font_size16   width-120 height-40"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Modal
                data-testid="saveModal" 
                className="delete_modal"
                // title="Basic Modal"

                closable={false}
                visible={saveModal}
                // onOk={handleOk1}
                // onCancel={handleCancel1}
                centered
              >
                <Row
                  align="middle"
                  justify="center"
                  className="font_size26 mb-1"
                >
                  Save Changes
                </Row>
                <Row
                  align="middle"
                  justify="center"
                  style={{ textAlignLast: "center" }}
                  className="font_size16 mb-1"
                >
                  Do you want to save the changes that <br /> you've made in the
                  content?
                </Row>
                <Row align="middle" justify="center">
                  <Col>
                    <Button
                      onClick={handleOk1}
                      className="ant-modal-btn-secondary font_size16 font_700 mt-1 ml-1 width-120 height-40"
                      style={{ width: "130px" }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      // loading={loading}
                      // onClick={handleCancel1}
                      onClick={updateDisclaimer}
                      className="ant-modal-btn-primary mt-1 ml-1 font_size16 font_700  height-40"
                      style={{ width: "130px" }}
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Col>
            <Col xl={1} />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditingDisclaimerWeb;
