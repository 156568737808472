//@ts-nocheck
import {
  SettingFilled,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Col, Row, Avatar, Menu, Dropdown } from "antd";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
// import logo from "./image_Greenapple.png"
const logo = require("./image_Greenapple.png");
import "../../blocks/user-profile-basic/src/ContentReportsWeb";
import "../../../../template-app/src/App.css"
const AdminHeaderWeb = (props: any) => {
  const [showMenu, setShowMenu] = useState(false);

  const menu = (
    <Menu className="">
      <Menu.Item key="0">
        <NavLink to="/ContentReportsWeb">
          {" "}
          <Col
            className="text_white"

          // onClick={() =>
          //   props.navigation.history.push("/ContentReportsWeb")
          // }
          >
            Content Reports
          </Col>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        {/* <Col
          className="pr-1 cursor_p header"
          onClick={() =>
            window.location.replace("https://www.fluentaac.com/learn-aac")
          }
        >
          AAC Lessons
        </Col> */}
        <NavLink to="/AccountWeb">
          {" "}
          <Col className="text_white">Account</Col>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        {/* <Col
          className="pr-1 cursor_p header"
          onClick={() =>
            window.location.replace("https://www.fluentaac.com/learn-aac")
          }
        >
          AAC Lessons
        </Col> */}
        <NavLink to="/UserSettingsWeb">
          <Col className="text_green">
            <SettingOutlined translate="true" />
          </Col>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">

        <Col className="text_white">
          {" "}
          <Avatar size="small" icon={<UserOutlined translate="true" />} />
        </Col>

      </Menu.Item>
    </Menu>
  );

  const dropDownMenu = (
    <Menu>
      <Menu.Item key="0" className="ant-dropdown-menu-item">
        <NavLink to="/admin/AdminUpdateProfileWeb">
          {" "}
          <Col
            className="text_white"
          // onClick={() =>
          //   props.navigation.history.push("/ContentReportsWeb")
          // }
          >
            Update Profile
          </Col>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1" className="ant-dropdown-menu-item">
        {/* <Col
        className="pr-1 cursor_p header"
        onClick={() =>
          window.location.replace("https://www.fluentaac.com/learn-aac")
        }
      >
        AAC Lessons
      </Col> */}
        <NavLink to="/admin/AdminSettingWeb">
          {" "}
          <Col className="text_white">Change Password</Col>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2" className="ant-dropdown-menu-item" >
        {/* <Col
        className="pr-1 cursor_p header"
        onClick={() =>
          window.location.replace("https://www.fluentaac.com/learn-aac")
        }
      >
        AAC Lessons
      </Col> */}
        <NavLink to="/admin/login">
          <Col className="text_green">
            Sign Out
          </Col>
        </NavLink>
      </Menu.Item>

    </Menu>
  )



  const handleManu = (e: any) => {
    e.preventDefault();
    setShowMenu(true);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 570;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  // useEffect(() => {
  //   console.log(window.innerWidth,"hhhhhhhh");
  // }, [])

  // const { height, width } = useWindowDimensions();
  return (
    <Row
      justify="center"
      align="middle"
      className="p-1"
    // style={{ height: "100px" }}
    >
      <Col
        //  lg={18} md={21} sm={23} xs={23}
        span={23}
      >
        <Row align="middle" className="header_border ">
          <Col span={4}>
            <NavLink to="/admin/Analytics3Web" exact={true}>
              <img
                src={logo}
                alt="logo"
                height="50px"
                width="230px"
              // onClick={() => props.navigation.history.push("/AccountWeb")}
              />
            </NavLink>
          </Col>
          <Col span={20}>
            <Row justify="end">
              {width < breakpoint ? (
                <>
                  <Dropdown className="" overlay={menu} trigger={["click"]}>
                    <Row className="" onClick={() => handleManu}>
                      <MenuOutlined translate="true" style={{ fontSize: "18px" }} />
                    </Row>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Row justify="end" style={{justifyContent: "space-between"}}>
                    <Col>
                      <NavLink
                        exact={true}
                        to="/admin/AdminUpdateSettingWeb"
                        activeClassName="active"
                        className="text_green font_size16 inactive"
                      >
                        <SettingOutlined translate="true" />
                      </NavLink>
                    </Col>
                    <Col className="pl-3">
                      <Dropdown className="" overlay={dropDownMenu } trigger={["click"]} >
                        <Row className="" onClick={() => handleManu}>
                          <Avatar size="small" style={{cursor: "pointer"}} icon={<UserOutlined translate="true" />} />
                        </Row>
                      </Dropdown>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AdminHeaderWeb;
