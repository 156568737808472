import React, { useEffect, useState } from "react";
import { Modal, Radio, Row, Col, Button,Spin } from "antd";
import "../assets/login.css";
import { openNotification } from "../../../components/src/Notification.web";
import axios from "axios";
import { UnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "./urlConfig";
import "../assets/disclaimerWeb.css"

const {baseUrl} = urlRepacthaConfig();
const DisclaimerWeb = (props: any) => {
  const handleOk = () => {
    console.log("ss");
  };

  const handleCancel = () => {
    console.log("ss");
  };
  const token: any = localStorage.getItem("user_token");

  const [status, setStatus] = useState('')
  const onChange = (checkedValues: any) => {
    console.log("checked = ", checkedValues.target.value);
    setStatus(checkedValues.target.value)
  };
  const handleSubmit = async () => {
    if (status == "agree") {
      const data = {
        "disclaimer_status": status
      }
      await axios
        .patch(`${baseUrl}disclaimer`, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            accessToken: token,
          },
        })
        .then((res) => { 
          props.navigation.history.push("/ContentReportsWeb")
          openNotification(res.data.full_message, "success") 
        })
        .catch((err: any) => {
          openNotification(err?.response?.data?.errors[0]?.full_message, "error")
        }).finally(() => {
          setStatus('')
        })
    }
  }
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getDisclaimer()
  }, [])
  const [disclaimer, setDisclaimer] = useState('')
  const getDisclaimer = async () => {
    await axios
      .get(`${baseUrl}disclaimer/content`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          accessToken: token,
        },
      })
      .then((res: any) => { 
         setDisclaimer(res.data.disclaimer_content[0].detail); 
      })
      .catch((err: any) => { 
        err.response.status == 404||401 ? UnAuthorized(props) : ""
        openNotification(err?.response?.data?.full_message, "error")
      }).finally(() => {
        setLoading(false);
      })
  };
  return (
    <div>
      <Modal
        width="50%"
        centered
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
      {loading?<Row align="middle" justify="center" data-testid="spinner" ><Col>
      <Spin size="large"  />
      </Col></Row> :<>
        <Row align="middle" justify="center" data-testid="content">
          <Col span={24}>
            <h1 className="text_white" style={{ textAlign: "center" }}>
              Disclaimer
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p dangerouslySetInnerHTML={{ __html:disclaimer }} className="text_white" style={{ textAlign: "left" }}>
               {/* {disclaimer} */}
            </p>
          </Col>
        </Row> 
        <Row align="middle" justify="center" className="mt-1">
          <Col>
            <Radio.Group style={{ width: "100%" }} onChange={onChange}>
              <Row>
                <Col span={24}>
                <Radio value="agree" className="text_white">
                    I agree
                  </Radio>
                </Col>
                <Col span={24}>
                  <Radio value="disagree" className="text_white">
                    I disagree
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Col>
        </Row>
        <Row align="middle"   style={{justifyContent: "space-evenly"}} className="mt-1">
          <Col>
          <Button
              className="cancel_button"
              style={{
                color: "white",
                width: "130px",
                border: "none",
                borderRadius: "20px"
              }} onClick={() => (UnAuthorized(props))}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button style={{width:"130px"}} type="primary" className="ant-btn-primary" onClick={handleSubmit} disabled={status == "agree" ? false : true}>Continue</Button>
          </Col>
        </Row></>}
      </Modal>
    </div>
  );
};

export default DisclaimerWeb;
