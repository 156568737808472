//@ts-nocheck
export const UnAuthorized = (props: any) => {
  localStorage.removeItem("user_token");
  props.navigation.history.push("/");
};

export const AdminUnAuthorized = (props: any) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_id");
  props.navigation.history.push("/admin/login");
};

export const loginAuthorized = (props: any) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_id");
  props.navigation.history.push("/");
};

export function downloadFile(url: any) {
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
