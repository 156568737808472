import React, { useEffect, useState } from "react";
import {
  MailOutlined,
  LockOutlined,
  SyncOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  SoundOutlined,
  UserOutlined
  
} from "@ant-design/icons";

import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Divider,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";
import "../assets/signup.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios"
import { openNotification } from "../../../components/src/Notification.web";
import { UnAuthorized } from "../../../components/src/helper";
const logo = require("../../../components/src/image_Greenapple.png");
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";

const {baseUrl, recaptcha} =  urlRepacthaConfig();
const EmailAccountSignUpWeb = (props: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const checkAuth = () => {
    window.localStorage.getItem("user_token") ? props.navigation.history.push("/AccountWeb") :  localStorage.removeItem("user_token")
  }
  useEffect(() => {
    checkAuth()
  }, [])
  const onFinish = async (values: any) => {
    const data = {
      ...values, "g-recaptcha-response": recaptchaRes
    }
    setLoading(true);
    await axios
      .post(`${baseUrl}signup`, data)
      .then((res: any) => {
        console.log(res);
        props.navigation.history.push("/")
        form.resetFields();
        openNotification(res.data.success[0].account, "success")
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.account, "error")

      }).finally(() => {
        setLoading(false)
        setRecaptchaRes('')
        resetCaptcha()
      })
  };
  const [recaptchaRes, setRecaptchaRes] = useState('')
  let captcha: any;

  const onChange = (value: any) => {
    setRecaptchaRes(value);
  }

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
  };

  const handlePrivacyPage = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      props.navigation.history.push("/PrivacyPage")
     }
     const handleTerms = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      props.navigation.history.push("/TermsAndConditions")
     }
     const handlenotification = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      props.navigation.history.push("/NotificationSettings")
     }     
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  }
  return (<>
   <Row style={{ marginTop: "70px", marginBottom:"0px" , }} />
      <Row  justify="center">
          <img
                src={logo}
                alt="logo"
                height="50px"
                width="230px"
                // onClick={() => props.navigation.history.push("/AccountWeb")}
              />
          </Row>
    <Row align="middle" justify="center" className='mt-2'>
     
      <Col sm={18} md={13} lg={10} xl={9} xxl={9} xs={20}>
        <Row
          align="middle"
          justify="center"
          style={{ fontSize: "28px", fontWeight: 600, marginBottom: "20px" }}
        >
          Sign Up to Greenapple Entertainment
        </Row>
        <Form
          className="login_form"
          style={{ marginTop: "70px" }}
          //   {...formItemLayout}
          autoComplete="off"
          form={form}
          name="register"
          onFinish={onFinish}
        >
          <Row align="middle" justify="center">
            <Col sm={12} xs={24}>
              {" "}
              <Form.Item
                name="first_name"
                rules={[{ required: true, message: "Please input your First Name!" }]}
              >
                <Input
                 data-testid="firstName"
                  prefix={
                    <UserOutlined
                      translate="true"
                      style={{
                        color: "#676666",
                        fontSize: "19px",
                        marginRight: "7px",
                      }}
                    />
                  }
                  placeholder="First Name"
                  bordered={false}
                />
              </Form.Item>
            </Col>
            <Col sm={1} xs={0} />
            <Col sm={11} xs={24}>
              {" "}
              <Form.Item
                name="last_name"
                rules={[{ required: true, message: "Please input your Last Name!" }]}
              // style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              >
                <Input
                 data-testid="lastName"
                  prefix={
                    <UserOutlined
                      translate="true"
                      style={{
                        color: "#676666",
                        fontSize: "19px",
                        marginRight: "7px",
                      }}
                    />
                  }
                  placeholder="Last Name"
                  bordered={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
             data-testid="email"
              type="email"
              prefix={
                <MailOutlined
                  translate="true"
                  style={{
                    color: "#676666",
                    fontSize: "19px",
                    marginRight: "7px",
                  }}
                />
              }
              placeholder="Email Address"
              //   style={{ backgroundColor: "black", color: "white" }}
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }, {
              pattern: new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
              ),
              message:
                "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#",
            },]}
          >
            <Input.Password
              data-testid="password"
              className="input_login"
              prefix={
                <LockOutlined
                  translate="true"
                  style={{
                    color: "#676666",
                    fontSize: "21px",
                    marginRight: "7px",
                  }}
                />
              }
              type="password"
              placeholder="Password"
              bordered={false}
              iconRender={(visible) => (visible ? <EyeTwoTone  translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , position: "absolute" , color: "white"}}/> : <EyeInvisibleOutlined translate = "transform(-50%, -50%)" style={{marginLeft: "-50px" , color: "white" , position: "absolute"}} />)}
            />
          </Form.Item>
          {/* <Form.Item
            name="license_id"
            rules={[
            
              {
                required: true,
                message: "Please input License Id",
              },
            ]}
          >
            <Input
              type="number"
              prefix={
                <IdcardOutlined
                  translate="true"
                  style={{
                    color: "#676666",
                    fontSize: "19px",
                    marginRight: "7px",
                  }}
                />
              }
              placeholder="License Id"
              //   style={{ backgroundColor: "black", color: "white" }}
              bordered={false}
            />
          </Form.Item> */}

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
              },
            ]}
          >
               <Checkbox style={{cursor: "default"}} >
          <p style={{ color: "white" , cursor: "default"}}>
            {" "}
            By creating an account, you agree to our{" "}
            <a href="https://greenappleent.com/privacy-policy-terms-of-use/">
              <span style={{ color: "#63A921" ,cursor: "pointer"  }}>
               Privacy Policy,
              </span></a>{" "}
              <a href="https://greenappleent.com/terms-of-use/">
            <span style={{ color: "#63A921" ,cursor: "pointer"}} >
              Terms and Conditions,
            </span></a>{" "}
            and
            <span style={{ color: "#63A921",cursor: "pointer" }} >
              {" "}
              Notification settings.
            </span>{" "}
          </p>
        </Checkbox>
          </Form.Item>
          <Form.Item>
            <Row style={{ marginTop: "30px" }}>
              <ReCAPTCHA  ref={(r:any) => setCaptchaRef(r)}
              sitekey={recaptcha}
              onChange={onChange}
              theme="dark" />{" "}
            </Row>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                borderRadius: "10px",
                backgroundColor: "#63A921",
                border: "0px",
                color: "black",
                fontSize: "21px",
                fontWeight: 600,
                padding: "5px",
                height: "50px",
                width: "200px",
              }}
            >
              Send Request
            </Button>
          </Form.Item>
          <p style={{cursor: "default"}} >
            Already register?{" "}
            <span
              className="cursor_pointer text_green"
              onClick={() =>
                props.navigation.history.push("/")
              }
            >
              Sign in
            </span>
          </p>
        </Form>
      </Col>
    </Row>
  </>);
};
export default EmailAccountSignUpWeb;
