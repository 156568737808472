import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Pagination,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
} from "antd";

import HeaderWeb from "../../../components/src/HeaderWeb";
import SideBarWeb from "../../../components/src/SidebarWeb";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import { CloseCircleOutlined } from "@ant-design/icons";

import axios from "axios";

import { openNotification } from "../../../components/src/Notification.web";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
const { baseUrl } = urlRepacthaConfig();

const EditIcon = require("./Edit@3x.png");
const PermissionWeb = (props: any) => {
  const [editModal, setEditmodal] = useState(false);
  const [paginationData, setPaginationData] = useState<any>([]);
  const [permissinData, setPermissinData] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const columns: any =  [
    {
      title: "LICENSOR",
      dataIndex: "email",
      width: "20%", 
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      key: "email",           
    },

    {
      title: "LICENSOR ID",
      dataIndex: "liecensor_id",
      width: "20%",
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => {
        const licensorIdA = a.liecensor_id ? parseInt(a.liecensor_id, 10) : 0;
        const licensorIdB = b.liecensor_id ? parseInt(b.liecensor_id, 10) : 0;
        return licensorIdA - licensorIdB;
      },
      key: "liecensor_id",      
    },

    {
      title: "EDIT",
      dataIndex: "edit",
      width: "10%",
      render: (key: any, row: any) => (
        <img
          data-testid="editBtn"
          height={34}
          width={34}
          src={EditIcon}
          style={{cursor: "pointer"}}
          onClick={() => {
            setEditmodal(true);
            getUserDetails(row.id);
          }}
        />
      ),
    },
  ];
  const token: any = localStorage.getItem("access_token");
  const getUserList = async () => {
    await axios
      .get(`${baseUrl}permission/user`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        // console.log(res.data.permitted_user, "ressssss");

        setPermissinData(res.data.permitted_user);
      })
      .catch((err: any) => {
        // console.log(err, "errrrr");
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  useEffect(() => {
    getUserList();
  }, []);
  const [editUser, setEditUser] = useState<any>("");
  const getUserDetails = async (id: any) => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/permission/${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res) => {
        setEditUser(res.data.permitted_user);
        form.setFieldsValue({
          email: res.data.permitted_user.email,
          client_id: res.data.permitted_user.client_id,
          liecensor_id: res.data.permitted_user.liecensor_id,
          picture_id: res.data.permitted_user.picture_id,
          // address: res.data.user_detail.address,
          // city: res.data.user_detail.city,
        });
      })
      .catch((err: any) => {
        // openNotification(err?.response?.data?.errors[0]?.full_message, "error")
        openNotification(err?.response?.data?.full_message, "error");
      })
      .finally(() => {
        // setStatus('')
        setLoading(false);
      });
  };
  const [editedPermissionDetails, setEditedPermissionDetails] = useState("");
  const handleEdit1 = (values: any) => {
    console.log(values, "valuesvalues");

    setEditedPermissionDetails(values);
    setEditmodal(false);
    setSaveModal(true);
  };

  const handleEdit = async () => {
    setBtnLoading(true);
    await axios
      .patch(
        `${baseUrl}update/permission/${editUser.id}`,
        editedPermissionDetails,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: token,
          },
        }
      )
      .then((res) => {
        openNotification(res.data.full_message, "success");
        getUserList();
        // setEditmodal(false);
        setSaveModal(false);
      })
      .catch((err: any) => {
        // openNotification(err?.response?.data?.errors[0]?.full_message, "error")
        openNotification(err?.response?.data?.full_message, "error");
        // err.response.status == 404 || 401 ? AdminUnAuthorized(props) : ""
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  // setPaginationData(result.data.meta.pagination);
  const onPageChange = (pageNumber: number) => {
    // getData(pageNumber);
  };
  const [form] = Form.useForm();
  const { Option } = Select;
  const [view, setView] = useState(false);
  const [data1, setdata1] = useState([
    {
      title: "USERS",
      key: "1",
      user: "John Brown",
      clientId: "456781",
      licensorId: "656234",
      pictureId: "456781",
      edit: <img height={34} width={34} src={EditIcon} />,
    },
  ]);

  const handleCancel = () => {
    setEditmodal(false);
  };

  const [saveModal, setSaveModal] = useState(false);
  const handleCancel1 = () => {
    setSaveModal(false);
  };
  const handleOk1 = () => {
    setSaveModal(false);
  };

  const [initLoading, setInitLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [listData, setListData] = useState();
  const [list, setList] = useState();

  const onLoadMore = () => {
    setListLoading(true);
    // setList(listData.concat(
    //   [...new Array(count)].map(() => ({ loading: true, name: {}, picture: {} })),
    // ),)
  };

  return (
    <>
      <AdminHeaderWeb />

      <Row className="mt-5">
        <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col lg={18} xl={20} md={17} sm={16} xs={14}>
          <Row
            // style={{ padding: "0px 12px" }}
            className="font_size23 mb-1 font_700 "
          >
            FilmItNow Access
            {/* <Col xl={1} /> */}
          </Row>
          <Row className="text_white font_size16 font_600 mb-1">
            {" "}
            List of Licensors
          </Row>
          {/* <Col xl={1}/> */}
          {/* <Col xs={24} sm={24} xl={23}> */}
          <Row style={{ width: "100%" }}>
            <Col
              xs={24}
              sm={24}
              xl={23}
              className="text_white p-5 mb-2"
            
            >
       
              
                <Table
                  // rowSelection={{
                  //   ...rowSelection,
                  // }}
                  // pagination={true}
                  loading={dataLoading}
                  columns={columns}
                  dataSource={permissinData}
                   
                  scroll={{ x: 700, y: 240 }}
                  rowKey={"id"}

                  pagination={false}
                  className="user_table"
                />
              

        
            </Col>
            <Col xl={1} />
          </Row>
     
          <Modal
            data-testid="editmodal"
            title="Edit Licensor"
            visible={editModal}
            onCancel={handleCancel}
            footer={false}
            className="edit_modal Modal_form"
            centered
          >
            {loading ? (
              <Row align="middle" justify="center">
                <Col>
                  <Spin size="large" />
                </Col>
              </Row>
            ) : (
              <>
                <Form autoComplete="off" form={form} onFinish={handleEdit1}>
                  <Row className="text_title mb-1">Email</Row>
                  <Form.Item name="email">
                    <Input
                      type="name"
                      className="height-42"
                      disabled
                      //   style={{ backgroundColor: "black", color: "white" }}
                      bordered={false}
                    />
                  </Form.Item>
                  {/* <Row className="text_title mb-1">Client ID</Row> */}
                  {/* <Form.Item
                  name="client_id"
                
                >
                  <Input className="height-42" placeholder="" bordered={false} />
                </Form.Item> */}
                  <Row className="text_title mb-1">Licensor ID</Row>
                  <Form.Item
                    name="liecensor_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Licensor Id!",
                      },
                      {
                        pattern: /^[^\s]+$/,
                        message: "Spaces are not allowed",
                      }   
                    ]}
                  >
                    <Input
                      className="height-42"
                      placeholder=""
                      bordered={false}
                    />
                  </Form.Item>
                  {/* <Row className="text_title mb-1">Picture ID</Row>
                <Form.Item
                  name="picture_id"
              
                >
                  <Input className="height-42" placeholder="" bordered={false} />
                </Form.Item> */}

                  <Row align="middle" justify="end" className="mt-2">
                    <Form.Item>
                      <Button
                        data-testid="save edit"
                        type="primary"
                        htmlType="submit"
                        className=" ant-modal-btn-primary  font_size16"
                        // loading={loading}  `

                        style={{ width: "120px", height: "35px" }}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </>
            )}
          </Modal>
          <Modal
            data-testid="editSaveModal"
            className="delete_modal"
            // title="Basic Modal"
            closable={false}
            visible={saveModal}
            // onOk={handleOk3}
            // onCancel={handleCancel3}
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              Save Changes
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              Do you want to save the current changes?
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={handleOk1}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  loading={btnLoading}
                  onClick={handleEdit}
                  className="ant-modal-btn-primary  mt-1 ml-1  width-120 height-40"
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default PermissionWeb;
