import { Col, Row, Table, Select, Button, Modal, Input } from "antd";
import React, { useState, useEffect } from "react";

import SideBarWeb from "../../../components/src/SidebarWeb";
import { CheckOutlined, CloseOutlined} from "@ant-design/icons";
import AdminHeaderWeb from "../../../components/src/AdminHeaderWeb";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import { AdminUnAuthorized } from "../../../components/src/helper";
import urlRepacthaConfig from "../../email-account-login/src/urlConfig";
import "../../../../../template-app/src/App.css"
const { Option } = Select;
const { baseUrl } = urlRepacthaConfig();

const RequestListWeb = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, tableSetData] = useState<any[]>();
  const [dataLoading, setDataLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [acceptMultipleModal, setAcceptMultipleModal] = useState(false);
  const [rejectMulipleModal, setRejectMulipleModal] = useState(false);
  const [handleUserId, setHandleUserId] = useState("");
  const [handleUserName, setHandleUserName] = useState("");
  const [searchLicensor, setSearchLicensor] = useState("");

  const columns: any[] = [
    {
      title: "LICENSOR",
      dataIndex: "name",
      filteredValue: [searchLicensor],
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name)
        }
      },
      onFilter: (value: any, record: any) => {
         if (record.name && record.name.toLowerCase().includes(value.toLowerCase())) return record
      }
    },
    {
      title: "REQUESTED ON",
      dataIndex: "date",
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a.date && b.date) {

          const first: any = new Date(a.date);
          const second: any = new Date(b.date);

          const result: any = first - second
          return result

        }
      }
    },
    {
      title: "ACCEPT",
      dataIndex: "accept",
      width: 80
    },
    {
      title: "REJECT",
      dataIndex: "reject",
      width: 80
    },
  ];

  const formatDate =  (dateString:string)=>{
      
   const date =  new Date(dateString).toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'} );
  return date

         
  }



  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {

      setSelectedRowKeys(selectedRowKeys);



      console.log('ids are', selectedRows, selectedRowKeys)
    }

  };

  const token: any = localStorage.getItem("access_token");


  const getUserList = async () => {
    await axios
      .get(`${baseUrl}admin/requests/user`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      })
      .then((res: any) => {
        // tableSetData(res.data.requested_user);
        // tableSetData
        let resData: any[] = [];
        res.data.requested_user.map((data: any, i: any) => {
          resData.push({
            key: data.id,
            name: `${data.first_name} ${data.last_name}`,
            date: `${formatDate(data.created_at)}`,
            accept: (
              <>
                <Button
                  data-testid="acceptBtn"
                  type="primary"
                  shape="circle"
                  icon={<CheckOutlined translate />}
                  className="admin-requestlist-web-tbl-accept-btn"
                  onClick={() =>
                    showModal(data.id, data.first_name + " " + data.last_name)
                  }
                />
              </>
            ),
            reject: (
              <Button
                type="primary"
                shape="circle"
                icon={<CloseOutlined translate />}
                className="admin-requestlist-web-tbl-reject-btn"
                onClick={() =>
                  showModalReject(
                    data.id,
                    data.first_name + " " + data.last_name
                  )
                }
              />
            ),
          });
        });

        tableSetData(resData);
        setDataLoading(false);
      })
      .catch((error: any) => {
        error.response.status == 404 ? tableSetData([]) : "";
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const approveUser = async (id: any) => {

    console.log("random ud", id)

    await axios
      .patch(`${baseUrl}admin/requests/approve/${id}`, "", {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
          // authorization: token,
        },
      })
      .then((res: any) => {
        setLoading(false);
        setVisible(false);
        getUserList();
        openNotification(res.data.full_message, "success");
      })
      .catch((err: any) => {
        // err.response.status == 404 || 401 ? AdminUnAuthorized(props) : "";
        // err.response.status == 404 || 401 ? AdminUnAuthorized(props) : "";
        // console.log(err)
      });
  };

  const rejectUser = async (id: any) => {
    await axios
      .patch(`${baseUrl}admin/requests/reject/${id}`, "", {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
          // authorization: token,
        },
      })
      .then((res: any) => {
        // console.log(res, "reject Data---")

        setRejectLoading(false);
        setVisibleReject(false);
        getUserList();
        openNotification(res.data.full_message, "success");
      })
      .catch((err: any) => {
      });
  };

  const handleMultipleUserAcceptReject = async () => {
    const data = {user_ids: selectedRowKeys}
    await axios
      .patch(`${baseUrl}admin/requests/${acceptMultipleModal ? 'approve_multiple' : 'reject_multiple'}`, data,{
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        }

      }).then((res) => {
        setAcceptMultipleModal(false);
        setRejectMulipleModal(false);
        getUserList();
        setSelectedRowKeys([])
        openNotification(res.data.full_message, "success");
      })
      .catch((err) => {
        openNotification(err?.response?.data?.full_message, "error");
        if (err.response.status === 401 || 404) {
          AdminUnAuthorized(props);
          return;
        }
        return err.response;
      })
}
 
useEffect(() => {
    getUserList();
  }, []);

  const showModal = (id: any, name: any) => {
    setHandleUserName(name);
    setHandleUserId(id);
    setVisible(true);
  };

  const showModalReject = (id: any, name: any) => {
    setHandleUserName(name);
    setHandleUserId(id);
    setVisibleReject(true);
  };

  const handleOk = () => {
    setLoading(true);
    approveUser(handleUserId);
  };

  const handleRejectOk = () => {
    setRejectLoading(true);
    rejectUser(handleUserId);
    setSelectedRowKeys([])
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleRejectCancel = () => {
    setVisibleReject(false);
  };

  return (
    <>
      <AdminHeaderWeb />
      <Row className="mt-5">
        <Col lg={5} xl={4} md={6} sm={7} xs={10}>
          <SideBarWeb props={props} />
        </Col>{" "}
        <Col
          lg={18}
          xl={20}
          md={17}
          sm={16}
          xs={14}
          className="admin-requestlist-web"
        >
          <Row className="mb-1">
            <Col xs={24} lg={24} xl={23}>
              <Row
                justify="space-between"
                style={{ padding: "0px 12px" }}
                className="font_size23 font_500 mb-1"
              >
                <Col>
                  <h3 className="text_white font_700">Requests</h3>
                  <p className="main2-head-txt-admin-home mb-0">
                    {" "}
                    List of Licensors
                  </p>
                </Col>

                <Col >
                  <Row className="input-box">
                    <Input.Search
                      placeholder="Search Licensor..."
                      onSearch={(value) => { setSearchLicensor(value) }}

              
                      onChange={(e) => setSearchLicensor(e.target.value)}
                      data-testid="searchLicense"
                    />
                  </Row>
                </Col>

                <Col style={{ minWidth: "220px" }}>
                  {selectedRowKeys.length > 1 && <Row className="flex-column" >
                    <Col >
                      <Button type="primary"
                        htmlType="submit"
                        className=" font_size16 accept-btn"
                        onClick={() => setAcceptMultipleModal(true)}
                      >
                        Accept Selected
                      </Button>
                    </Col>
                    <Col>
                      <Button data-testid="add_new_user"
                        type="primary"
                        htmlType="submit"
                        className=" font_size16 delte_btn"
                        onClick={() => setRejectMulipleModal(true)}
                      >Reject Selected</Button>
                    </Col>
                  </Row>
                  }
                </Col>
              </Row>
            </Col>
            <Col xl={1} />

          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              xl={23}
              style={{
                padding: "18px",
                backgroundColor: "#222222",
                minWidth: "500px",
                borderRadius: "20px",
              }}
            >
              <Table
                pagination={false}
                data-testid="table"
                loading={dataLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
                className="admin-requestlist-web-tbl"
                scroll={{ x: 700, y: 240 }}

              />

            </Col>
            <Col xl={1} />
          </Row>

          <Modal
            className="admin-settings-pwd-update-modal"
            visible={visible}
            data-testid="modal"
            //   title="Title"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Row
                className="admin-settings-pwd-update-modal-footer-sec"
                justify="center"
              >
                <Button
                  key="back"
                  onClick={handleCancel}
                  className="admin-settings-pwd-update-modal-footer-sec-cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                  className="admin-settings-pwd-update-modal-footer-sec-save-btn"
                >
                  Yes
                </Button>
              </Row>,
            ]}
          >
            <p className="admin-settings-pwd-update-modal-head">
              Accept Selections
            </p>
            <p className="admin-settings-pwd-update-modal-txt">
              You have selected {handleUserName && handleUserName}. Are you sure
              you want to accept it ?
            </p>
          </Modal>

          <Modal
            className="admin-settings-pwd-update-modal"
            visible={visibleReject}
            //   title="Title"
            onOk={handleRejectOk}
            onCancel={handleRejectCancel}
            footer={[
              <Row
                className="admin-settings-pwd-update-modal-footer-sec"
                justify="center"
              >
                <Button
                  key="back"
                  onClick={handleRejectCancel}
                  className="admin-settings-pwd-update-modal-footer-sec-cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={rejectLoading}
                  onClick={handleRejectOk}
                  className="admin-settings-pwd-update-modal-footer-sec-save-btn"
                >
                  Yes
                </Button>
              </Row>,
            ]}
          >
            <p className="admin-settings-pwd-update-modal-head">
              Reject Selections
            </p>
            <p className="admin-settings-pwd-update-modal-txt">
              You have selected {handleUserName && handleUserName}. Are you sure
              you want to reject it ?
            </p>
          </Modal>

          <Modal
            data-testid="acceptMultipleModal"
            visible={acceptMultipleModal || rejectMulipleModal}
            closable={false}
            className="delete_modal"
            centered
          >
            <Row align="middle" justify="center" className="font_size26 mb-1">
              {acceptMultipleModal ? 'Accept' : 'Reject'} Selections
            </Row>
            <Row align="middle" justify="center" className="mb-2">
              You have selected {selectedRowKeys.length} users. <br />
              <span>Are you sure you want to {acceptMultipleModal ? 'accept' : 'reject'} selections?</span>
            </Row>
            <Row align="middle" justify="center">
              <Col>
                <Button
                  onClick={() => {
                    if (acceptMultipleModal) {
                      return setAcceptMultipleModal(false)
                    }

                    setRejectMulipleModal(false)
                   
                  }}
                  className="ant-modal-btn-secondary mt-1 ml-1  width-120 height-40"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                 
                  onClick={
                    handleMultipleUserAcceptReject
                  }
                  className="ant-modal-btn-primary  mt-1 ml-1  width-120 height-40"
                >
                  Yes
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default RequestListWeb;
